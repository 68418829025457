import { AnimatePresence, motion } from "framer-motion";
import axios from "axios";
import { toast } from "react-toastify";
import Okation from "../../Assets/OkationLogo.png";
import boxMind from "../../Assets/boxMind_wide.png";
import AI from "../../Assets/AI.jpg";
import { useState } from "react";
import { useNavigate } from "react-router";
import { BiSolidShow, BiSolidHide } from "react-icons/bi";

function SignUp() {
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const user_name = e.currentTarget.user_name.value;
    const user_email = e.currentTarget.user_email.value;
    const user_password = e.currentTarget.user_password.value;

    if (user_password !== confirmPassword) {
      toast.error("Passwords do not match!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
      return;
    }

    const passwordPattern =
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#$%^&+=!])(?=.{8,})/;

    if (!passwordPattern.test(user_password)) {
      toast.error(
        "Password must contain at least 1 capital letter, 1 small letter, 1 special character, 1 number, and be at least 8 characters long.",
        {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "dark",
        }
      );
      return;
    }

    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    if (!emailPattern.test(user_email)) {
      toast.error("Email format is not valid", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
      return;
    }

    try {
      await axios.post(
        `${process.env.REACT_APP_API}${process.env.REACT_APP_REGISTER}`,
        {
          user_name,
          user_email,
          user_password,
        }
      );
      navigate("/signin");
      toast.success("User has been created successfully!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        toast.error(error.response?.data.message || "An error occurred", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "dark",
        });
        console.error("Signup failed", error);
        if (error.response?.data) {
          console.log("Error response data:", error.response.data);
        }
      } else {
        toast.error("An error occurred", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "dark",
        });
        console.error("Signup failed", error);
      }
    }
  };

  const handleConfirmPasswordChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setConfirmPassword(e.target.value);
  };

  const handleTogglePasswordVisibility = () => {
    setIsVisible(!isVisible);
  };

  return (
    <section className=" md:grid grid-flow-row-dense grid-cols-10 ">
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          viewport={{ once: false }}
          transition={{ duration: 0.7 }}
          className=" w-full col-span-3"
        >
          <form
            onSubmit={handleSubmit}
            className=" w-full flex flex-col justify-center items-center mx-auto h-screen bg-gray-200"
          >
            <div className="my-6">
              <img src={boxMind} width={220} alt="TurboMind" loading="lazy" />
            </div>
            <div className=" flex flex-col gap-4">
              <p className=" flex justify-center text-[40px] font-bold text-[#135991]">
                Sign Up
              </p>
              <div className=" my-6 flex flex-col gap-5">
                <div className=" flex flex-row justify-between items-center gap-5 text-[#135991]">
                  <p className=" font-bold">Username</p>
                  <input
                    required
                    type="text"
                    name="user_name"
                    placeholder="Username"
                    className=" border p-2 outline-none rounded-lg "
                  />
                </div>
                <div className=" flex flex-row justify-between items-center gap-5 text-[#135991]">
                  <p className=" font-bold">Email</p>
                  <input
                    required
                    type="email"
                    name="user_email"
                    placeholder="Email"
                    className=" border p-2 outline-none rounded-lg"
                  />
                </div>
                <div className=" relative flex flex-row justify-between items-center gap-5 text-[#135991]">
                  <p className=" font-bold">Password</p>
                  <input
                    required
                    type={isVisible ? "text" : "password"}
                    name="user_password"
                    placeholder="Password"
                    className=" border p-2 outline-none rounded-lg"
                  />
                  <button
                    type="button"
                    onClick={handleTogglePasswordVisibility}
                    className="outline-none text-[#135991] absolute right-2"
                  >
                    {isVisible ? <BiSolidShow /> : <BiSolidHide />}
                  </button>
                </div>
                <div className=" flex flex-row justify-between items-center gap-5 text-[#135991]">
                  <p className=" font-bold">Confirm Password</p>
                  <input
                    required
                    type={isVisible ? "text" : "password"}
                    name="confirm_password"
                    placeholder="Confirm Password"
                    className=" border p-2 outline-none rounded-lg"
                    onChange={handleConfirmPasswordChange}
                  />
                </div>
              </div>
              <button
                type="submit"
                className=" bg-[#135991] hover:bg-[#062c47] duration-300 p-4 rounded-lg text-white font-semibold"
              >
                Sign Up
              </button>
              <div className=" flex flex-col items-center justify-center pt-3 ">
                <div className=" flex flex-row font-bold text-[12px] gap-0.5">
                  <span className="text-[#135991]"> Already a User?</span>{" "}
                  <a
                    href="/signin"
                    className=" text-blue-700 underline hover:text-blue-800 duration-300"
                  >
                    Sign In
                  </a>
                </div>
                <div className="flex flex-row items-center gap-2 pt-14">
                  <span className="text-[#135991] font-semibold">
                    Powered by
                  </span>{" "}
                  <a
                    href="https://boxmind.ai/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={Okation}
                      width={40}
                      alt="Okation"
                      loading="lazy"
                      className=" flex justify-center items-center"
                    />
                  </a>
                </div>
              </div>
            </div>
          </form>
        </motion.div>
      </AnimatePresence>
      <div className=" col-span-7 w-full h-screen max-sm:hidden">
        <img src={AI} alt="AI" className="w-full h-full" loading="lazy" />
      </div>
    </section>
  );
}

export default SignUp;
