//for electron use HashRouter instead of BrowserRouter
import { BrowserRouter, Route, Routes } from "react-router-dom";
// import Prompt from "./components/Dashboard/DashboardComponents/Prompt";
// import ParentModel from "./components/Dashboard/DashboardComponents/Info/ParentModel/ParentModel";
import Users from "./components/Dashboard/DashboardComponents/Admin/Users/Users";
import Roles from "./components/Dashboard/DashboardComponents/Admin/Roles/Roles";
import Models from "./components/Dashboard/DashboardComponents/Info/Models/Models";
import Agents from "./components/Dashboard/DashboardComponents/Info/Agents/Agents";
import Dashboard from "./components/Dashboard/Dashboard";
import SearchEngine from "./components/Dashboard/DashboardComponents/SearchEngine";
import NewChat from "./components/Chat/NewChat";
// import FileManger from "./components/Dashboard/DashboardComponents/FileManager/FileManager";
// import UploadFile from "./components/Dashboard/DashboardComponents/UploadFile";
import Profile from "./components/Dashboard/DashboardComponents/Profile/Profile";
import UpgradeToPro from "./components/Dashboard/DashboardComponents/Profile/UpgradeToPro";
import ProfileSettings from "./components/Dashboard/DashboardComponents/Profile/ProfileSettings";
import Dropbox from "./components/Dashboard/DashboardComponents/DropBox/DropBox";
import Google from "./components/Dashboard/DashboardComponents/GoogleDrive/GoogleDrive";
import ProtectedRoute from "./Routes/ProtectedRoute";
import PublicRoute from "./Routes/PublicRoute";
import NotFoundPage from "./NotFoundPage/NotFoundPage";
import SignIn from "./components/SignIn/SignIn";
import SignUp from "./components/SignUp/SignUp";
import OneDrive from "./components/Dashboard/DashboardComponents/OneDrive/OneDrive";
import FileUpload from "./components/Dashboard/DashboardComponents/FileUpload";
import Colletions from "./components/Dashboard/DashboardComponents/Info/Collection/Collections";
import Gmail from "./components/Dashboard/DashboardComponents/Gmail/Gmail";
import PromptEng from "./components/Dashboard/DashboardComponents/PromptEng";
import { useEffect, useState } from "react";
import AllFiles from "./components/Dashboard/DashboardComponents/AllFiles";
import CloudConnectors from "./components/Dashboard/DashboardComponents/Connectors/CloudConnectors";
import EmailConnectors from "./components/Dashboard/DashboardComponents/Connectors/EmailConnectors";
import Jobs from "./components/Dashboard/DashboardComponents/Job/Jobs";
import Outlook from "./components/Dashboard/DashboardComponents/Outlook/Outlook";
import UsePrompt from "./components/Dashboard/DashboardComponents/PromptEng/UsePrompt";
import { ImSpinner2 } from "react-icons/im";
import HomeScreen from "./components/Dashboard/DashboardComponents/HomeScreen";
import AgentFiles from "./components/Dashboard/DashboardComponents/AgentFiles";
import Cookies from "js-cookie";
import GroupAgents from "./components/Dashboard/DashboardComponents/Info/GroupAgents/GroupAgents";
import EditRole from "./components/Dashboard/DashboardComponents/Admin/Roles/EditRole";
import JobDetails from "./components/Dashboard/DashboardComponents/Job/JobDetails";
import Feedback from "./components/Dashboard/DashboardComponents/Feedback";
import AdminDashboard from "./components/Dashboard/DashboardComponents/AdminDashboard/index";

function App() {
  interface Permissions1 {
    object_id: string;
    object_name: string;
    permissions: {
      allow_add: boolean | null;
      allow_delete: boolean | null;
      allow_edit: boolean | null;
      allow_view: boolean | null;
      [key: string]: boolean | null;
    };
  }

  const [userID, setUserID] = useState<any | null>(null);
  const [userData, setUserData] = useState<any | null>(null);
  const initialPermissions: Permissions1[] = [];

  const [userPermissions, setUserPermissions] =
    useState<Permissions1[]>(initialPermissions);

  const user_id = localStorage.getItem("user_id");
  const [loading, setLoading] = useState(true);
  const [loading1, setLoading1] = useState(true);
  const [refresh, setRefresh] = useState(0);

  const token = Cookies.get("access_token");

  useEffect(() => {
    if (user_id === null) {
      setLoading(false);
      setLoading1(false);
    } else {
      const apiUrl1 = `${process.env.REACT_APP_API}${process.env.REACT_APP_USER_PERMISSIONS}${user_id}`;
      fetch(apiUrl1, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data1: any) => {
          setUserPermissions(data1);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          localStorage.removeItem("user_id");
          localStorage.removeItem("user_name");
          localStorage.removeItem("user_email");
        })
        .finally(() => setLoading(false));

      const apiUrl = `${process.env.REACT_APP_API}${process.env.REACT_APP_USER_DETAILS}${user_id}`;

      fetch(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data: any) => {
          setUserData(data);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          localStorage.removeItem("user_id");
          localStorage.removeItem("user_name");
          localStorage.removeItem("user_email");
        })
        .finally(() => setLoading1(false));
    }
  }, [user_id, refresh]);

  const getAllowViewForName = (searchName: string): boolean | null => {
    const foundPermission = userPermissions.find(
      (permission: any) => permission.object_name === searchName
    );

    if (foundPermission) {
      return foundPermission.permissions.allow_view;
    }
    return null;
  };

  if (loading || loading1) {
    return (
      <div className=" flex justify-center items-center w-screen h-screen">
        <ImSpinner2 size={25} className="animate-spin" />
      </div>
    );
  }

  return (
    <div className=" overflow-hidden">
      <BrowserRouter>
        <Routes>
          {/* Protected Routes */}
          <Route element={<ProtectedRoute />}>
            {userPermissions && getAllowViewForName("Chat") && (
              <Route path="/chat" element={<NewChat userData={userData} />} />
            )}
            {userPermissions && getAllowViewForName("AI Search") && (
              <Route path="/ai-search" element={<SearchEngine />} />
            )}
            <Route
              path="/"
              element={
                <Dashboard
                  userData={userData}
                  userPermissions={userPermissions}
                  setUserData={setUserData}
                  setUserID={setUserID}
                />
              }
            >
              {userPermissions && getAllowViewForName("Feedback") && (
                <Route path="/feedback" element={<Feedback />} />
              )}

              {/* Dashboard Routes */}
              {userPermissions && getAllowViewForName("GenAI") && (
                <>
                  {userPermissions && getAllowViewForName("Data Agents") && (
                    <Route
                      path="/data-agents"
                      element={<Agents userPermissions={userPermissions} />}
                    />
                  )}
                  {userPermissions && getAllowViewForName("Group Agents") && (
                    <Route
                      path="/group-agents"
                      element={
                        <GroupAgents userPermissions={userPermissions} />
                      }
                    />
                  )}
                  {userPermissions && getAllowViewForName("LLM Models") && (
                    <Route
                      path="/llm-models"
                      element={<Models userPermissions={userPermissions} />}
                    />
                  )}
                  {userPermissions && getAllowViewForName("Collections") && (
                    <Route path="/collections" element={<Colletions />} />
                  )}
                  {userPermissions && getAllowViewForName("Upload File") && (
                    <Route path="/upload-file" element={<FileUpload />} />
                  )}
                  {userPermissions && getAllowViewForName("My Files") && (
                    <Route
                      path="/my-files"
                      element={<AllFiles userPermissions={userPermissions} />}
                    />
                  )}
                  {userPermissions && getAllowViewForName("Agent Files") && (
                    <Route
                      path="/agent-files"
                      element={<AgentFiles userPermissions={userPermissions} />}
                    />
                  )}
                </>
              )}
              <Route path="" element={<HomeScreen />} />
              <Route path="/admin-panel" element={<AdminDashboard />} />
              {userPermissions && getAllowViewForName("Users") && (
                <Route
                  path="/users"
                  element={<Users userPermissions={userPermissions} />}
                />
              )}
              {userPermissions && getAllowViewForName("Roles") && (
                <>
                  <Route
                    path="/roles"
                    element={
                      <Roles
                        userPermissions={userPermissions}
                        setRefreshUserData={setRefresh}
                        refreshUserData={refresh}
                      />
                    }
                  />
                  <Route
                    path="/roles/edit_role/:role_id"
                    element={<EditRole setRefreshUserData={setRefresh} />}
                  />
                </>
              )}

              {/* <Route path="/parent-model" element={<ParentModel />} /> */}
              {/* <Route path="/prompts" element={<Prompt />} /> */}
              {/* <Route path="/file-manager" element={<FileManger />} /> */}
              {/* <Route path="/upload" element={<UploadFile />} /> */}
              <Route
                path="/profile"
                element={<Profile userData={userData} />}
              />
              <Route path="/upgrade-to-pro" element={<UpgradeToPro />} />

              {userPermissions && getAllowViewForName("AI Forms") && (
                <>
                  <Route
                    path="/ai-forms"
                    element={
                      <PromptEng
                        userData={userData}
                        userPermissions={userPermissions}
                      />
                    }
                  />
                  <Route
                    path="/use-prompt/:ai_form_id"
                    element={<UsePrompt />}
                  />
                </>
              )}

              {userPermissions && getAllowViewForName("Cloud Storage") && (
                <>
                  <Route
                    path="/cloud-connectors"
                    element={<CloudConnectors userData={userData} />}
                  />
                  {userData &&
                    userData.data &&
                    userData?.data.connected_to_dropbox === true && (
                      <Route path="/dropbox" element={<Dropbox />} />
                    )}
                  {userData &&
                    userData.data &&
                    userData?.data.connected_to_google_drive === true && (
                      <Route path="/google" element={<Google />} />
                    )}
                  {userData &&
                    userData.data &&
                    userData?.data.connected_to_onedrive === true && (
                      <Route path="/onedrive" element={<OneDrive />} />
                    )}
                </>
              )}

              {userPermissions && getAllowViewForName("Email Storage") && (
                <>
                  <Route
                    path="/email-connectors"
                    element={<EmailConnectors userData={userData} />}
                  />
                  {userData?.data.connected_to_gmail === true && (
                    <Route path="/gmail" element={<Gmail />} />
                  )}

                  {userData?.data.connected_to_outlook === true && (
                    <Route path="/outlook" element={<Outlook />} />
                  )}
                </>
              )}

              {/* <Route path="/connectors" element={<Connectors />} /> */}

              {userPermissions && getAllowViewForName("AI Pipeline") && (
                <>
                  <Route path="/jobs" element={<Jobs />} />
                  <Route
                    path="/jobs/job_details/:job_name"
                    element={<JobDetails />}
                  />
                </>
              )}

              <Route
                path="/profile/profile-settings"
                element={
                  <ProfileSettings
                    userData={userData}
                    setRefresh={setRefresh}
                  />
                }
              />
            </Route>
          </Route>
          {/* Public Routes */}
          <Route element={<PublicRoute />}>
            <Route path="/signin" element={<SignIn setUserID={setUserID} />} />
            <Route path="/signup" element={<SignUp />} />
          </Route>
          <Route path="/*" element={<NotFoundPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
