import { useEffect, useState } from "react";
import { BiEdit } from "react-icons/bi";
import EditPrompt from "./EditPrompt";
import { ImSpinner } from "react-icons/im";
import { Link } from "react-router-dom";
import { AsyncImage } from "loadable-image";
import { Blur } from "transitions-kit";
import { fetchData } from "../../../GlobalFunctions";
import { getPermissionForName } from "../../../GlobalFunctions";

let currentIdDatabase = 0;

function PromptEng({ userPermissions }: any) {
  interface PromptData {
    ai_form_id: string;
    available_to_all_users: string;
    custom_form: {
      fields: [any];
      prompt: string;
      title: string;
      key_submit: string;
    };
    description: string;
    form_name: string;
    picture: string;
    system_form: { fields: [any] };
  }

  const [promptData, setPromptData] = useState<PromptData[] | null>(null);
  const [PromptEditOpen, setPromptEditOpen] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const user_id = localStorage.getItem("user_id") || "default_user";
  // const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);

  const openEditPrompt = (id: number) => {
    setPromptEditOpen(true);
    currentIdDatabase = id;
  };

  const closeEditPrompt = () => {
    setPromptEditOpen(false);
  };

  const handlePromptEditClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      closeEditPrompt();
    }
  };

  useEffect(() => {
    fetchData(
      `${process.env.REACT_APP_AIFORMS_FORMS_BY_USER}${user_id}`,
      setPromptData
    );
  }, [refresh]);

  const isAllowEdit =
    userPermissions &&
    getPermissionForName(userPermissions, "allow_edit", "AI Forms");

  return (
    <div>
      {promptData && promptData.length > 0 ? (
        <div className=" grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
          {promptData?.map((data, index) => (
            <div
              key={index}
              className=" relative flex flex-col m-4 hover:bg-gray-400 rounded-lg duration-300 bg-[#EDEAF8]"
            >
              <Link to={`/use-prompt/${data.ai_form_id}`} target="_blank">
                {userPermissions && isAllowEdit && (
                  <button
                    onClick={(e: any) => {
                      e.preventDefault();
                      openEditPrompt(index);
                    }}
                    className="absolute right-2 top-2 bg-[#4D30B5] hover:bg-[#1b1436] text-white p-2 rounded duration-300 z-20"
                  >
                    <BiEdit />
                  </button>
                )}
                <div className=" ">
                  <div className="flex justify-center">
                    <AsyncImage
                      className=" rounded-t-lg max-h-[300px] content-center "
                      src={`data:image/jpg;base64,${data?.picture}`}
                      alt={data?.form_name}
                      loading="lazy"
                      Transition={Blur}
                      style={{ width: 500, height: 300 }}
                    />
                  </div>
                </div>
                <div className=" bg-[#4D30B5] pt-2 pb-1 rounded-b-lg">
                  <p className=" text-[20px] text-white mb-2 text-center">
                    {data?.form_name}
                  </p>
                </div>
              </Link>
            </div>
          ))}
        </div>
      ) : (
        <div className="flex justify-center">
          <ImSpinner size={30} className=" animate-spin" />
        </div>
      )}
      {userPermissions &&
        isAllowEdit &&
        (promptData
          ? PromptEditOpen && (
              <EditPrompt
                handlePromptEditClick={handlePromptEditClick}
                closeEditPrompt={closeEditPrompt}
                descriptionEdit={promptData[currentIdDatabase].description}
                custom_form={promptData[currentIdDatabase].custom_form}
                form_id={promptData[currentIdDatabase].ai_form_id}
                setRefresh={setRefresh}
              />
            )
          : null)}
    </div>
  );
}

export default PromptEng;
