import { useEffect, useState } from "react";
import { ImSpinner, ImSpinner2 } from "react-icons/im";
import { toast } from "react-toastify";
import api from "../../../../api";
import { Link, useParams } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";
import { IoChevronBackSharp } from "react-icons/io5";

function EditRole({ setRefreshUserData }: any) {
  interface Forms {
    ai_form_id: string;
    form_name: string;
  }

  interface Agents {
    id: string;
    agent: string;
  }

  interface Groups {
    group_id: string;
    group_name: string;
  }

  interface Models {
    id: string;
    model_name: string;
  }

  interface Permissions1 {
    object_id: string;
    object_name: string;
    description: string;
    href: string;
    permissions: {
      allow_add: number | null;
      allow_delete: number | null;
      allow_edit: number | null;
      allow_view: number | null;
      [key: string]: number | null;
    };
  }

  interface RoleData {
    role_name: string;
  }

  const { role_id } = useParams();

  const [roleData, setRoleData] = useState<RoleData>();
  const [name, setName] = useState("");
  const [checkedModels, setCheckedModels] = useState<string[]>([]);
  const [checkedAgents, setCheckedAgents] = useState<string[]>([]);
  const [checkedGroups, setCheckedGroups] = useState<string[]>([]);
  const [checkedForms, setCheckedForms] = useState<string[]>([]);

  const [agents, setAgents] = useState<Agents[]>([]);
  const [groups, setGroups] = useState<Groups[]>([]);
  const [models, setModels] = useState<Models[]>([]);
  const [forms, setForms] = useState<Forms[]>([]);
  const [tabChange, setTabChange] = useState("general");
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);

  const initialPermissions: Permissions1[] = [];

  const [selectedPermissions, setSelectedPermissions] =
    useState<Permissions1[]>(initialPermissions);

  const handleTabClick = async (temp: any) => {
    setCheckedModels([]);
    setCheckedAgents([]);
    setCheckedGroups([]);
    setCheckedForms([]);
    setSelectedPermissions(initialPermissions);

    setLoading1(true);
    setLoading2(true);

    setTabChange(temp);
    if (temp === "general") {
      setLoading1(false);
      setLoading2(false);
    } else if (temp === "models") {
      try {
        const responseModels = await api.get(
          `${process.env.REACT_APP_ALL_MODEL}`
        );
        const dataModels = responseModels.data;
        setModels(dataModels);

        const responseRolesModels = await api.get(
          `${process.env.REACT_APP_LLM_DETAILS_BY_ROLE}${role_id}`
        );
        const dataRolesModels = responseRolesModels.data;
        setCheckedModels(dataRolesModels.map((model: any) => model.llm_id));

        setLoading1(false);
        setLoading2(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    } else if (temp === "agents") {
      try {
        const responseAgents = await api.get(
          `${process.env.REACT_APP_ALL_AGENTS}`
        );
        const dataAgents = responseAgents.data;
        setAgents(dataAgents);

        const responseRolesAgents = await api.get(
          `${process.env.REACT_APP_ROLE_DATA_AGENT}${role_id}`
        );
        const dataRolesAgents = responseRolesAgents.data;
        setCheckedAgents(
          dataRolesAgents.map((agent: any) => agent.data_agent_id)
        );

        setLoading1(false);
        setLoading2(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    } else if (temp === "groups") {
      try {
        const responseAgents = await api.get(
          `${process.env.REACT_APP_ROLES_AGENT_GROUPS}`
        );
        const agentGroups = responseAgents.data;
        setGroups(agentGroups);

        const responseRolesGroups = await api.get(
          `${process.env.REACT_APP_ROLES_AGENT_GROUPS_BY_ROLE}${role_id}`
        );
        const dataRolesGroups = responseRolesGroups.data;
        setCheckedGroups(dataRolesGroups.map((group: any) => group.group_id));

        setLoading1(false);
        setLoading2(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    } else if (temp === "forms") {
      try {
        const responseForms = await api.get(
          `${process.env.REACT_APP_ALL_FORMS}`
        );
        const dataForms = responseForms.data;
        setForms(dataForms);

        const responseRolesForms = await api.get(
          `${process.env.REACT_APP_AI_FORMS_BY_ROLE}${role_id}`
        );
        const dataRolesForms = responseRolesForms.data;
        setCheckedForms(dataRolesForms.map((form: any) => form.ai_form_id));

        setLoading1(false);
        setLoading2(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    } else if (temp === "permissions") {
      try {
        const responseRolesPermissions = await api.get(
          `${process.env.REACT_APP_ROLE_PERMISSIONS}${role_id}`
        );
        const dataRolesPermissions = responseRolesPermissions.data;
        setSelectedPermissions(dataRolesPermissions);

        setLoading1(false);
        setLoading2(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const handleModelsCheckBoxChange = (id: string) => {
    setCheckedModels((prevModels) => {
      if (prevModels.includes(id)) {
        return prevModels.filter((model_id) => model_id !== id);
      } else {
        return [...prevModels, id];
      }
    });
  };

  const handleAgentsCheckBoxChange = (id: string) => {
    setCheckedAgents((prevAgents) => {
      if (prevAgents.includes(id)) {
        return prevAgents.filter((agent_id) => agent_id !== id);
      } else {
        return [...prevAgents, id];
      }
    });
  };

  const handleGroupsCheckBoxChange = (id: string) => {
    setCheckedGroups((prevGroups) => {
      if (prevGroups.includes(id)) {
        return prevGroups.filter((group_id) => group_id !== id);
      } else {
        return [...prevGroups, id];
      }
    });
  };

  const handleFormsCheckBoxChange = (id: string) => {
    setCheckedForms((prevForms) => {
      if (prevForms.includes(id)) {
        return prevForms.filter((ai_form_id) => ai_form_id !== id);
      } else {
        return [...prevForms, id];
      }
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get(
          `${process.env.REACT_APP_ROLES_DETAILS_BY_ID}${role_id}`
        );
        const data = response.data;
        setRoleData(data);
        setName(data?.role_name);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleEditSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData();

    if (role_id !== undefined) {
      formData.append("role_id", role_id);
    }

    if (tabChange === "general") {
      formData.append("new_role_name", name);
    } else if (tabChange === "models") {
      formData.append("llm_models", JSON.stringify(checkedModels));
    } else if (tabChange === "agents") {
      formData.append("data_agent_id", JSON.stringify(checkedAgents));
    } else if (tabChange === "groups") {
      formData.append("group_ids", JSON.stringify(checkedGroups));
    } else if (tabChange === "forms") {
      formData.append("ai_form_id", JSON.stringify(checkedForms));
    } else if (tabChange === "permissions") {
      formData.append("object_id", JSON.stringify(selectedPermissions));
    }

    try {
      let endpoint = "";

      if (tabChange === "general") {
        endpoint = `${process.env.REACT_APP_ROLES_EDIT_USER_ROLE}`;
      } else if (tabChange === "models") {
        endpoint = `${process.env.REACT_APP_ROLES_EDIT_LLM_MODEL}`;
      } else if (tabChange === "agents") {
        endpoint = `${process.env.REACT_APP_ROLES_EDIT_DATA_AGENTS}`;
      } else if (tabChange === "groups") {
        endpoint = `${process.env.REACT_APP_ROLES_EDIT_AGENT_GROUPS}`;
      } else if (tabChange === "forms") {
        endpoint = `${process.env.REACT_APP_ROLES_EDIT_AI_FORM}`;
      } else if (tabChange === "permissions") {
        endpoint = `${process.env.REACT_APP_ROLES_EDIT_OBJECT_PERMISSION}`;
      }

      await api.post(endpoint, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setRefreshUserData((prevRefresh: any) => prevRefresh + 1);
      toast.success("Role has been edited successfully!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
    } catch (error) {
      toast.error("An error occurred while editing role", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
      console.error(error);
    }
  };

  const handleCheckboxChange = (object_id: string, permissionType: string) => {
    setSelectedPermissions((prevPermissions) => {
      return prevPermissions.map((permission) => {
        if (permission.object_id === object_id) {
          return {
            ...permission,
            permissions: {
              ...permission.permissions,
              [permissionType]:
                permission.permissions[permissionType] === 1 ? 0 : 1,
            },
          };
        }
        return permission;
      });
    });
  };

  return (
    <div>
      <div className="relative">
        <div className=" absolute">
          <Link to={"/roles"}>
            <div className=" flex flex-row items-center">
              <IoChevronBackSharp title="roles" />
              <p>Go Back</p>
            </div>
          </Link>
        </div>
        <p className="text-[25px] text-center content-center pb-4">
          Edit {tabChange} Section for {name} role
        </p>
        <div className=" flex flex-row justify-between gap-4 w-full">
          <div className=" flex flex-col gap-3 max-w-[200px] w-full pt-8">
            <button
              onClick={() => handleTabClick("general")}
              className={`flex flex-row justify-between items-center mr-2 px-2 py-2 border-b text-gray-800 border-gray-500 ${
                tabChange === "general"
                  ? "border-[#4D30B5] hover:border-[#1b1436] duration-300"
                  : " border-[#1b1436]"
              }`}
            >
              <p>General</p>
              <IoIosArrowForward />
            </button>
            <button
              onClick={() => handleTabClick("models")}
              className={`flex flex-row justify-between items-center mr-2 px-2 py-2 border-b text-gray-800 border-gray-500 ${
                tabChange === "models"
                  ? "border-[#4D30B5] hover:border-[#1b1436] duration-300"
                  : " border-[#1b1436]"
              }`}
            >
              <p>Models</p>
              <IoIosArrowForward />
            </button>
            <button
              onClick={() => handleTabClick("agents")}
              className={`flex flex-row justify-between items-center mr-2 px-2 py-2 border-b text-gray-800 border-gray-500 ${
                tabChange === "agents"
                  ? "border-[#4D30B5] hover:border-[#1b1436] duration-300"
                  : " border-[#1b1436]"
              }`}
            >
              <p>Agents</p>
              <IoIosArrowForward />
            </button>
            <button
              onClick={() => handleTabClick("groups")}
              className={`flex flex-row justify-between items-center mr-2 px-2 py-2 border-b text-gray-800 border-gray-500 ${
                tabChange === "groups"
                  ? "border-[#4D30B5] hover:border-[#1b1436] duration-300"
                  : " border-[#1b1436]"
              }`}
            >
              <p>Data Agent Groups</p>
              <IoIosArrowForward />
            </button>
            <button
              onClick={() => handleTabClick("forms")}
              className={`flex flex-row justify-between items-center mr-2 px-2 py-2 border-b text-gray-800 border-gray-500 ${
                tabChange === "forms"
                  ? "border-[#4D30B5] hover:border-[#1b1436] duration-300"
                  : " border-[#1b1436]"
              }`}
            >
              <p>AI Forms</p>
              <IoIosArrowForward />
            </button>
            <button
              onClick={() => handleTabClick("permissions")}
              className={`flex flex-row justify-between items-center mr-2 px-2 py-2 border-b text-gray-800 border-gray-500 ${
                tabChange === "permissions"
                  ? "border-[#4D30B5] hover:border-[#1b1436] duration-300"
                  : " border-[#1b1436]"
              }`}
            >
              <p>Permissions</p>
              <IoIosArrowForward />
            </button>
          </div>

          {loading1 === true || loading2 === true ? (
            <div className=" w-full flex justify-center items-center pt-16">
              <ImSpinner2 size={25} className="animate-spin" />
            </div>
          ) : (
            <>
              {tabChange === "general" ? (
                <form className=" w-full" onSubmit={handleEditSubmit}>
                  <div className=" flex flex-col gap-4">
                    <div className=" grid grid-cols-2 gap-2">
                      <div className="flex flex-col">
                        <label className="pb-2 text-[20px]">Role Name:</label>
                        <input
                          disabled={name === "user" || name === "admin"}
                          required
                          type="text"
                          value={name}
                          onChange={handleNameChange}
                          className=" border-[2px] text-black  outline-none p-1 rounded-lg"
                        />
                      </div>
                    </div>

                    {name === "user" || name === "admin" ? null : (
                      <div className="mt-4 flex justify-center">
                        <button
                          type="submit"
                          className="bg-[#4D30B5] hover:bg-[#1b1436] text-white px-5 py-3 rounded duration-300"
                        >
                          Edit Role
                        </button>
                      </div>
                    )}
                  </div>
                </form>
              ) : null}

              {tabChange === "models" ? (
                <form className=" w-full" onSubmit={handleEditSubmit}>
                  <div className="p-4 flex flex-col gap-4">
                    <div>
                      <label className="pb-2 text-[20px]">Models:</label>
                      <div className="z-10 bg-gray-100 rounded-lg shadow w-full mt-2 ">
                        <ul
                          className=" px-3 pb-3 overflow-y-auto text-sm md:grid md:grid-cols-3"
                          aria-labelledby="dropdownSearchButton"
                        >
                          {models && models.length > 0 ? (
                            models?.map((model, index) => {
                              if (checkedModels?.includes(model.id)) {
                                return (
                                  <li key={index}>
                                    <div className=" flex items-center p-2 rounded">
                                      <input
                                        defaultChecked
                                        id="checkbox-item-13"
                                        type="checkbox"
                                        value=""
                                        className="w-4 h-4 "
                                        onChange={() =>
                                          handleModelsCheckBoxChange(model.id)
                                        }
                                      />
                                      <label className="w-full ml-2 text-sm font-medium ">
                                        {model.model_name}
                                      </label>
                                    </div>
                                  </li>
                                );
                              } else {
                                return (
                                  <li key={index}>
                                    <div className="flex items-center p-2 rounded ">
                                      <input
                                        id="checkbox-item-14"
                                        type="checkbox"
                                        value={model.id}
                                        className="w-4 h-4 text-blue-600 "
                                        onChange={() =>
                                          handleModelsCheckBoxChange(model.id)
                                        }
                                      />
                                      <label className="w-full ml-2 text-sm font-medium ">
                                        {model.model_name}
                                      </label>
                                    </div>
                                  </li>
                                );
                              }
                            })
                          ) : (
                            <div className=" animate-spin flex justify-center">
                              <ImSpinner size={30} />
                            </div>
                          )}
                        </ul>
                      </div>
                    </div>

                    <div className="mt-4 flex justify-center">
                      <button
                        type="submit"
                        className="bg-[#4D30B5] hover:bg-[#1b1436] text-white px-5 py-3 rounded duration-300"
                      >
                        Edit Models
                      </button>
                    </div>
                  </div>
                </form>
              ) : null}

              {tabChange === "agents" ? (
                <form className=" w-full" onSubmit={handleEditSubmit}>
                  <div className="p-4 flex flex-col gap-4">
                    <div>
                      <label className="pb-2 text-[20px]">Agents:</label>
                      <div className="z-10 bg-gray-100 rounded-lg shadow w-full mt-2 ">
                        <ul
                          className=" px-3 pb-3 overflow-y-auto text-sm md:grid md:grid-cols-3"
                          aria-labelledby="dropdownSearchButton"
                        >
                          {agents && agents.length > 0 ? (
                            agents?.map((agent, index) => {
                              if (checkedAgents?.includes(agent?.id)) {
                                return (
                                  <li key={index}>
                                    <div className=" flex items-center p-2 rounded">
                                      <input
                                        defaultChecked
                                        id="checkbox-item-13"
                                        type="checkbox"
                                        value=""
                                        className="w-4 h-4 "
                                        onChange={() =>
                                          handleAgentsCheckBoxChange(agent?.id)
                                        }
                                      />
                                      <label className="w-full ml-2 text-sm font-medium ">
                                        {agent?.agent}
                                      </label>
                                    </div>
                                  </li>
                                );
                              } else {
                                return (
                                  <li key={index}>
                                    <div className="flex items-center p-2 rounded ">
                                      <input
                                        id="checkbox-item-14"
                                        type="checkbox"
                                        value={agent?.id}
                                        className="w-4 h-4 text-blue-600 "
                                        onChange={() =>
                                          handleAgentsCheckBoxChange(agent?.id)
                                        }
                                      />
                                      <label className="w-full ml-2 text-sm font-medium ">
                                        {agent?.agent}
                                      </label>
                                    </div>
                                  </li>
                                );
                              }
                            })
                          ) : (
                            <div className=" animate-spin flex justify-center">
                              <ImSpinner size={30} />
                            </div>
                          )}
                        </ul>
                      </div>
                    </div>

                    <div className="mt-4 flex justify-center">
                      <button
                        type="submit"
                        className="bg-[#4D30B5] hover:bg-[#1b1436] text-white px-5 py-3 rounded duration-300"
                      >
                        Edit Agents
                      </button>
                    </div>
                  </div>
                </form>
              ) : null}

              {tabChange === "groups" ? (
                <form className=" w-full" onSubmit={handleEditSubmit}>
                  <div className="p-4 flex flex-col gap-4">
                    <div>
                      <label className="pb-2 text-[20px]">Groups:</label>
                      <div className="z-10 bg-gray-100 rounded-lg shadow w-full mt-2 ">
                        <ul
                          className=" px-3 pb-3 overflow-y-auto text-sm md:grid md:grid-cols-3"
                          aria-labelledby="dropdownSearchButton"
                        >
                          {groups && groups.length > 0 ? (
                            groups?.map((group, index) => {
                              if (checkedGroups?.includes(group?.group_id)) {
                                return (
                                  <li key={index}>
                                    <div className=" flex items-center p-2 rounded">
                                      <input
                                        defaultChecked
                                        id="checkbox-item-13"
                                        type="checkbox"
                                        value=""
                                        className="w-4 h-4 "
                                        onChange={() =>
                                          handleGroupsCheckBoxChange(
                                            group?.group_id
                                          )
                                        }
                                      />
                                      <label className="w-full ml-2 text-sm font-medium ">
                                        {group?.group_name}
                                      </label>
                                    </div>
                                  </li>
                                );
                              } else {
                                return (
                                  <li key={index}>
                                    <div className="flex items-center p-2 rounded ">
                                      <input
                                        id="checkbox-item-14"
                                        type="checkbox"
                                        value={group?.group_id}
                                        className="w-4 h-4 text-blue-600 "
                                        onChange={() =>
                                          handleGroupsCheckBoxChange(
                                            group?.group_id
                                          )
                                        }
                                      />
                                      <label className="w-full ml-2 text-sm font-medium ">
                                        {group?.group_name}
                                      </label>
                                    </div>
                                  </li>
                                );
                              }
                            })
                          ) : (
                            <div className=" animate-spin flex justify-center">
                              <ImSpinner size={30} />
                            </div>
                          )}
                        </ul>
                      </div>
                    </div>

                    <div className="mt-4 flex justify-center">
                      <button
                        type="submit"
                        className="bg-[#4D30B5] hover:bg-[#1b1436] text-white px-5 py-3 rounded duration-300"
                      >
                        Edit Groups
                      </button>
                    </div>
                  </div>
                </form>
              ) : null}

              {tabChange === "forms" ? (
                <form className=" w-full" onSubmit={handleEditSubmit}>
                  <div className="p-4 flex flex-col gap-4">
                    <div>
                      <label className="pb-2 text-[20px]">Forms:</label>
                      <div className="z-10 bg-gray-100 rounded-lg shadow w-full mt-2 ">
                        <ul
                          className=" px-3 pb-3 overflow-y-auto text-sm md:grid md:grid-cols-3"
                          aria-labelledby="dropdownSearchButton"
                        >
                          {forms && forms.length > 0 ? (
                            forms?.map((form, index) => {
                              if (checkedForms?.includes(form.ai_form_id)) {
                                return (
                                  <li key={index}>
                                    <div className="flex items-center p-2 rounded">
                                      <input
                                        defaultChecked
                                        id="checkbox-item-13"
                                        type="checkbox"
                                        value=""
                                        className="w-4 h-4 "
                                        onChange={() =>
                                          handleFormsCheckBoxChange(
                                            form.ai_form_id
                                          )
                                        }
                                      />
                                      <label className="w-full ml-2 text-sm font-medium ">
                                        {form.form_name}
                                      </label>
                                    </div>
                                  </li>
                                );
                              } else {
                                return (
                                  <li key={index}>
                                    <div className="flex items-center p-2 rounded ">
                                      <input
                                        id="checkbox-item-14"
                                        type="checkbox"
                                        value={form.ai_form_id}
                                        className="w-4 h-4 text-blue-600 "
                                        onChange={() =>
                                          handleFormsCheckBoxChange(
                                            form.ai_form_id
                                          )
                                        }
                                      />
                                      <label className="w-full ml-2 text-sm font-medium ">
                                        {form.form_name}
                                      </label>
                                    </div>
                                  </li>
                                );
                              }
                            })
                          ) : (
                            <div className=" animate-spin flex justify-center">
                              <ImSpinner size={30} />
                            </div>
                          )}
                        </ul>
                      </div>
                    </div>

                    <div className="mt-4 flex justify-center">
                      <button
                        type="submit"
                        className="bg-[#4D30B5] hover:bg-[#1b1436] text-white px-5 py-3 rounded duration-300"
                      >
                        Edit AI Forms
                      </button>
                    </div>
                  </div>
                </form>
              ) : null}

              {tabChange === "permissions" ? (
                <form className="w-full" onSubmit={handleEditSubmit}>
                  <div className="p-4 flex flex-col gap-4">
                    <div>
                      <label className="pb-2 text-[20px]">Permissions:</label>
                      <div className="z-10 bg-gray-100 rounded-lg shadow w-full mt-2 ">
                        <div
                          className="table-container"
                          style={{
                            maxHeight: "calc(100vh - 290px)",
                            overflowY: "auto",
                          }}
                        >
                          <table className="w-full table-auto">
                            <thead className="sticky top-0 bg-gray-200">
                              <tr>
                                <th className="py-2 px-4">Name</th>
                                <th className="py-2 px-4">View</th>
                                <th className="py-2 px-4">Create</th>
                                <th className="py-2 px-4">Edit</th>
                                <th className="py-2 px-4">Delete</th>
                              </tr>
                            </thead>
                            <tbody>
                              {selectedPermissions.map((permission, index) => (
                                <tr className="hover:bg-gray-100" key={index}>
                                  <td className="py-2 px-4 text-center border">
                                    {permission.object_name}
                                  </td>
                                  <td className="py-2 px-4 text-center border">
                                    {permission.permissions.allow_view !==
                                    null ? (
                                      <input
                                        type="checkbox"
                                        checked={
                                          permission.permissions.allow_view ===
                                          1
                                        }
                                        onChange={() =>
                                          handleCheckboxChange(
                                            permission.object_id,
                                            "allow_view"
                                          )
                                        }
                                        className="w-4 h-4"
                                      />
                                    ) : null}
                                  </td>
                                  <td className="py-2 px-4 text-center border">
                                    {permission.permissions.allow_add !==
                                    null ? (
                                      <input
                                        type="checkbox"
                                        checked={
                                          permission.permissions.allow_add === 1
                                        }
                                        onChange={() =>
                                          handleCheckboxChange(
                                            permission.object_id,
                                            "allow_add"
                                          )
                                        }
                                        className="w-4 h-4"
                                      />
                                    ) : null}
                                  </td>
                                  <td className="py-2 px-4 text-center border">
                                    {permission.permissions.allow_edit !==
                                    null ? (
                                      <input
                                        type="checkbox"
                                        checked={
                                          permission.permissions.allow_edit ===
                                          1
                                        }
                                        onChange={() =>
                                          handleCheckboxChange(
                                            permission.object_id,
                                            "allow_edit"
                                          )
                                        }
                                        className="w-4 h-4"
                                      />
                                    ) : null}
                                  </td>
                                  <td className="py-2 px-4 text-center border">
                                    {permission.permissions.allow_delete !==
                                    null ? (
                                      <input
                                        type="checkbox"
                                        checked={
                                          permission.permissions
                                            .allow_delete === 1
                                        }
                                        onChange={() =>
                                          handleCheckboxChange(
                                            permission.object_id,
                                            "allow_delete"
                                          )
                                        }
                                        className="w-4 h-4"
                                      />
                                    ) : null}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                        {/* {permissions && permissions.length > 0 ? (
                          permissions?.map((permission, index) => {
                            if (checkedPermissions?.includes(permission.id)) {
                              return (
                                <li key={index}>
                                  <div className="flex items-center p-2 rounded">
                                    <input
                                      defaultChecked
                                      id="checkbox-item-13"
                                      type="checkbox"
                                      value=""
                                      className="w-4 h-4 "
                                      onChange={() =>
                                        handlePermissionsCheckBoxChange(
                                          permission.id
                                        )
                                      }
                                    />
                                    <label className="w-full ml-2 text-sm font-medium ">
                                      {permission.object}
                                    </label>
                                  </div>
                                </li>
                              );
                            } else {
                              return (
                                <li key={index}>
                                  <div className="flex items-center p-2 rounded ">
                                    <input
                                      id="checkbox-item-14"
                                      type="checkbox"
                                      value={permission.id}
                                      className="w-4 h-4 text-blue-600 "
                                      onChange={() =>
                                        handlePermissionsCheckBoxChange(
                                          permission.id
                                        )
                                      }
                                    />
                                    <label className="w-full ml-2 text-sm font-medium ">
                                      {permission.object}
                                    </label>
                                  </div>
                                </li>
                              );
                            }
                          })
                        ) : (
                          <div className=" animate-spin flex justify-center">
                            <ImSpinner size={30} />
                          </div>
                        )} */}
                      </div>
                    </div>

                    <div className="mt-4 flex justify-center">
                      <button
                        type="submit"
                        className="bg-[#4D30B5] hover:bg-[#1b1436] text-white px-5 py-3 rounded duration-300"
                      >
                        Edit Permissions
                      </button>
                    </div>
                  </div>
                </form>
              ) : null}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
export default EditRole;
