import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import api from "../../../api";
import { ImSpinner } from "react-icons/im";
import { FaThumbsUp, FaThumbsDown } from "react-icons/fa";

const Feedback = () => {
  const today = new Date();
  const oneYearAgo = new Date();
  oneYearAgo.setFullYear(today.getFullYear() - 1);

  const formatDate = (date: Date) => {
    return date.toISOString().split("T")[0];
  };

  const [fromDate, setFromDate] = useState<string>(formatDate(oneYearAgo));
  const [toDate, setToDate] = useState<string>(formatDate(today));
  const [loading, setLoading] = useState(true);
  const [feedbackData, setFeedbackData] = useState<any[]>([]);
  const [filter, setFilter] = useState<string>("all");

  useEffect(() => {
    setLoading(true);

    const processFeedbackData = (data: any[]) => {
      return data.map((item) => ({
        userName: item.user_name,
        like: item.like,
        dislike: item.dislike,
        feedback: item.dislike ? item.feedback_text : null,
        question: item.question,
        answer: item.answer,
      }));
    };

    async function fetchData1() {
      try {
        const formData = new FormData();

        if (fromDate !== undefined) {
          formData.append("from", fromDate);
        }
        if (toDate !== undefined) {
          formData.append("to", toDate);
        }

        const response = await api.post(
          `${process.env.REACT_APP_API}${process.env.REACT_APP_FEEDBACK_REVIEW}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        const data = response.data;
        const processedData = processFeedbackData(data);
        setFeedbackData(processedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData1();

    setLoading(false);
  }, [fromDate, toDate]);

  const handleFromDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newFromDate = e.target.value;
    if (toDate && new Date(newFromDate) > new Date(toDate)) {
      toast.error("From date cannot be after to date!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
    } else {
      setFromDate(newFromDate);
    }
  };

  const handleToDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newToDate = e.target.value;
    if (fromDate && new Date(newToDate) < new Date(fromDate)) {
      toast.error("To date cannot be before from date!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
    } else {
      setToDate(newToDate);
    }
  };

  const filteredFeedbackData = feedbackData.filter((feedback) => {
    if (filter === "like") return feedback.like;
    if (filter === "dislike") return feedback.dislike;
    return true;
  });

  return (
    <section
      className="overflow-scroll h-screen"
      style={{ maxHeight: "calc(100vh - 50px)" }}
    >
      <div className="p-2">
        <h2 className="text-2xl font-semibold text-gray-800 mb-6">
          Feedback Analytics
        </h2>
        <div className="space-y-6">
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Filter Feedback
            </label>
            <select
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
              className="w-full px-4 py-2.5 bg-gray-50 border border-gray-300 text-gray-900 rounded-lg transition-all duration-300 outline-none"
            >
              <option value="all">All</option>
              <option value="like">Likes</option>
              <option value="dislike">Dislikes</option>
            </select>
          </div>
          <div className="grid md:grid-cols-2 gap-4">
            <div className="relative">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                From Date
              </label>
              <input
                required
                type="date"
                value={fromDate}
                onChange={handleFromDateChange}
                className="w-full px-4 py-2.5 bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 transition-all duration-300"
              />
            </div>
            <div className="relative">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                To Date
              </label>
              <input
                required
                type="date"
                value={toDate}
                onChange={handleToDateChange}
                className="w-full px-4 py-2.5 bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 transition-all duration-300"
              />
            </div>
          </div>
        </div>
      </div>
      {loading ? (
        <div className="flex justify-center mt-10">
          <ImSpinner size={30} className=" animate-spin" />
        </div>
      ) : (
        <div className="p-4">
          {filteredFeedbackData.map((feedback, index) => (
            <div
              key={index}
              className="border rounded-lg p-4 mb-4 shadow-md bg-white"
            >
              <h3 className="text-lg font-semibold">{feedback.userName}</h3>
              <div className="flex items-center">
                {feedback.like ? (
                  <FaThumbsUp className="text-green-500 mr-2" />
                ) : (
                  <FaThumbsDown className="text-red-500 mr-2" />
                )}
                <p className="text-gray-600">
                  {feedback.like
                    ? "Liked"
                    : feedback.dislike
                    ? "Disliked"
                    : "No Reaction"}
                </p>
              </div>
              {feedback.feedback && (
                <div className="mt-2">
                  <p className="font-medium text-red-600">Feedback:</p>
                  <p>{feedback.feedback}</p>
                </div>
              )}
              <div className="mt-2">
                <p className="font-medium">Question:</p>
                <p>{feedback.question}</p>
              </div>
              <div className="mt-2">
                <p className="font-medium">Answer:</p>
                <div className=" max-h-56 overflow-y-auto rounded py-2">
                  <p>{feedback.answer}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </section>
  );
};

export default Feedback;
