import React, { useState } from "react";
import api from "../api";

const FEEDBACK_SUGGESTIONS = [
  "Incorrect or inaccurate information",
  "Not relevant to my question",
  "Unclear or confusing response",
  "Missing important details",
  "Not factually correct",
  "Other",
];

interface DislikeFeedbackProps {
  onClose: () => void;
  chatDetailsId: string;
  onSubmit: () => void;
}

const DislikeFeedback: React.FC<DislikeFeedbackProps> = ({
  onClose,
  chatDetailsId,
  onSubmit,
}) => {
  const [feedback, setFeedback] = useState("");

  const handleSubmit = async () => {
    try {
      await api.post(`${process.env.REACT_APP_FEEDBACK}`, {
        chat_details_id: chatDetailsId,
        like_status: "0",
        feedback_text: feedback,
      });
      onSubmit();
    } catch (error) {
      console.error("Error submitting feedback:", error);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 max-w-md w-full">
        <h2 className="text-xl font-bold mb-4">What went wrong?</h2>

        <div className="grid grid-cols-2 gap-3 mb-4">
          {FEEDBACK_SUGGESTIONS.map((suggestion) => (
            <button
              key={suggestion}
              onClick={() => setFeedback(suggestion)}
              className="block w-full px-4 py-2 rounded bg-gray-100 hover:bg-gray-300 duration-300"
            >
              {suggestion}
            </button>
          ))}
        </div>

        <textarea
          value={feedback}
          onChange={(e) => setFeedback(e.target.value)}
          placeholder="Please provide additional details..."
          className="w-full p-2 border rounded mb-4 min-h-[100px]"
        />

        <div className="flex justify-end space-x-2">
          <button
            onClick={onClose}
            className="px-4 py-2 text-gray-600 hover:text-gray-800 duration-300"
          >
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            disabled={!feedback.trim()}
            className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 disabled:bg-gray-400 duration-300"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default DislikeFeedback;
