import React, { useEffect, useState, useRef } from "react";
import { io } from "socket.io-client";
import {
  AiOutlineCopy,
  AiOutlineLike,
  AiOutlineDislike,
  AiFillLike,
  AiFillDislike,
  AiOutlineClose,
} from "react-icons/ai";
import { BsFiletypeCsv, BsThreeDots } from "react-icons/bs";
import { HiMiniClipboardDocumentCheck } from "react-icons/hi2";
import { VscSend } from "react-icons/vsc";
import Logo from "../../Assets/OkationLogo.png";
import { FiSidebar } from "react-icons/fi";
import Okation from "../../Assets/boxMind_wide_white.png";
import Turbo from "../../Assets/boxMind_wide.png";
import ChatUser from "../../Assets/ChatPerson.png";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactDOM from "react-dom";
import { HiOutlineRefresh } from "react-icons/hi";
import ChatHistory from "./History/ChatHistory";
import DeleteAllRooms from "../Chat/History/DeleteAllRooms";
import Model from "../Chat/Model";
import Agent from "../Chat/Agent";
import Tag from "../Chat/Tag";
import CustomInstructions from "../Chat/CustomInstructions";
import { CiFileOn, CiStop1 } from "react-icons/ci";
import Cookies from "js-cookie";
import EmailInfo from "./EmailInfo";
import api from "../api";
import { AsyncImage } from "loadable-image";
import { Blur } from "transitions-kit";
import { AnimatePresence, motion } from "framer-motion";
import { Link } from "react-router-dom";
import { GoImage } from "react-icons/go";
import { MdOutlineCancel } from "react-icons/md";
import { CgAttachment } from "react-icons/cg";
import { PiMicrosoftExcelLogoLight } from "react-icons/pi";
import ReactMarkdown from "react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { dracula } from "react-syntax-highlighter/dist/esm/styles/prism";
import remarkMath from "remark-math";
import rehypeMathJax from "rehype-mathjax";
import remarkGfm from "remark-gfm";
import DislikeFeedback from "./DislikeFeedback";

let currentInputVar = 0;
let currentCodeInputVar = 0;
let currentImgInputVar = 0;
let firsttoken = 0;
let lastgeneratingcode = 0;
let newChatRefresh = 0;
let imageBase64Data = "";
let currentImageInputVar = 0;
let printcode = false;
let printgeneratecode = false;
let printimage = false;

let search_engine =
  Cookies.get("search_engine") !== undefined ? Cookies.get("search_engine") : 0;
let pdf_name = Cookies.get("pdf_name") ? Cookies.get("pdf_name") : "";
let selected_tag_search = Cookies.get("selected_tag")
  ? Cookies.get("selected_tag")
  : "";
let selected_agent_search = Cookies.get("selected_agent")
  ? Cookies.get("selected_agent")
  : "";
let selected_tag_name_search = Cookies.get("selected_tag")
  ? Cookies.get("selected_tag_name")
  : "";
let selected_agent_name_search = Cookies.get("selected_agent")
  ? Cookies.get("selected_agent_name")
  : "";

interface Message {
  type: "input" | "response" | "clipboard";
  message: JSX.Element | string;
  chat_details_id?: string;
}

interface History {
  chat_details_id: string;
  room_id: number;
  question: string;
  answer: string;
  error: string;
  response_time: string;
  pdf_name: string;
  like: string;
  dislike: string;
  is_regenerated: string;
  user_comments: string;
  question_date_time: string;
  faithfulness: string;
  answer_relevancy: string;
  context_relevancy: string;
  debug: number;
  sources: string;
  image: string;
}

interface TagData {
  id: string;
  tag_name: string;
}

const Thinking = [
  "Searching...",
  "Fetching the data...",
  "Generating response...",
];

const examples: string[] = [
  "my printer took a timespan of 5 seconds to answer. is this normal ?",
  "For the Zero % vat what is the Branch/ ARM Process?",
  "which insurance company covers critical care?",
  "Can i cancel my insurance policy?",
  "what happens if an Individual cheque is lost or it's stolen?",
  "should remittance applications formers must be stamped?",
];

interface MindAgentData {
  id: string;
  agent: string;
  logo: string;
  description: string;
}

function NewChat({ userData }: any): JSX.Element {
  const [messageList, setMessageList] = useState<Message[]>([]);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [inputMessage, setInputMessage] = useState<string>("");

  const clientId = localStorage.getItem("user_id");
  const user_name = userData?.data?.user_name;

  const [debugMode, setDebugMode] = useState<string>("0");

  const [selectedRoomId, setSelectedRoomId] = useState<number>(
    Math.round(Math.random() * 1e9)
  );

  //visibility
  const [sidebarVisible, setSidebarVisible] = useState<boolean>(true);
  const [menuVisible, setMenuVisible] = useState<boolean>(false);
  const [moreOptions, setMoreOptions] = useState<boolean>(false);

  //References
  const chatContainerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLTextAreaElement>(null);

  const [loading, setLoading] = useState<boolean>(false);
  const [responseStarted, setResponseStarted] = useState<boolean>(false);
  const [messageIndex, setMessageIndex] = useState(0);
  const [previousQuestion, setPreviousQuestion] = useState<string>("");

  //Model
  const [modelActive, setModelActive] = useState<boolean>(false);
  const [selectedModel, setSelectedModel] = useState<string>("boxMind");

  //Agent
  const [mindAgentData, setMindAgentData] = useState<MindAgentData[] | null>(
    null
  );
  const [agentActive, setAgentActive] = useState<boolean>(false);
  const [selectedAgent, setSelectedAgent] = useState<string | null>(null);
  const [selectedAgentId, setSelectedAgentId] = useState<string | null>(null);

  const [refreshRooms, setRefreshRooms] = useState(0);

  //tag
  const [tagActive, setTagActive] = useState<boolean>(false);
  const [selectedTag, setSelectedTag] = useState(
    selected_tag_search ? selected_tag_search : null
  );
  const [selectedTagName, setSelectedTagName] = useState(
    selected_tag_name_search ? selected_tag_name_search : ""
  );
  const [canResetTag, setCanResetTag] = useState("0");

  //Custom Instructions
  const [customInstructions, setCustomInstructions] = useState(null);
  const [customInstructionsName, setCustomInstructionsName] = useState("");
  const [customInstructionsActive, setCustomInstructionsActive] =
    useState<boolean>(false);

  //Email
  const [isEmailInfoOpen, setEmailInfoOpen] = useState(false);
  const [emailData, setEmailData] = useState("");
  const [tokenChanged, setTokenChanged] = useState<string[]>([]);

  //History Chat
  const [historyData, setHistoryData] = useState<History[]>([]);

  //Image
  const [expandedImage, setExpandedImage] = useState(null);

  //Exel
  const [file, setFile] = useState<File | null>(null);

  const [isScrolledUp, setIsScrolledUp] = useState<boolean>(false);
  const [shuffledExamples, setShuffledExamples] = useState<string[]>([]);
  const [openDeleteAll, setOpenDeleteAll] = useState(false);

  const user_id = localStorage.getItem("user_id") || "default_user";
  const [mindTagData, setMindTagData] = useState<TagData[] | null>(null);
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [chatDetailsId, setChatDetailsId] = useState<string | null>(null);

  const [showDislikeFeedback, setShowDislikeFeedback] =
    useState<boolean>(false);
  const [currentFeedbackId, setCurrentFeedbackId] = useState<string>("");

  //Agent Fetch
  useEffect(() => {
    const fetchData = async () => {
      setLoading1(true);
      setLoading2(true);
      try {
        const responseAgents = await api.get(
          `${process.env.REACT_APP_AGENTS_BY_USER}${user_id}`
        );
        const dataAgents = responseAgents.data;
        setMindAgentData(dataAgents);

        if (dataAgents && dataAgents.length > 0) {
          setSelectedAgent(
            selected_agent_name_search
              ? selected_agent_name_search
              : dataAgents[0].agent
          );
          setSelectedAgentId(
            selected_agent_search ? selected_agent_search : dataAgents[0].id
          );
        }

        setLoading1(false);
        setLoading2(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [user_id]);

  //tag fetch
  useEffect(() => {
    const apiUrl = `${process.env.REACT_APP_TAGS_BY_AGENT}${selectedAgentId}`;

    if (canResetTag === "1") {
      setSelectedTag("");
      setSelectedTagName("");
    }
    api
      .get(apiUrl)
      .then((response) => {
        const data = response.data;
        setMindTagData(data);
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, [selectedAgentId, canResetTag]);

  const handleImageClick = (image: any) => {
    setExpandedImage(image);
  };

  const handleCloseModal = () => {
    setExpandedImage(null);
  };

  Cookies.remove("pdf_name");
  Cookies.remove("search_engine");
  Cookies.remove("selected_agent");
  Cookies.remove("selected_tag");
  Cookies.remove("selected_agent_name");
  Cookies.remove("selected_tag_name");

  //Show
  const showAgent = () => {
    setAgentActive(!agentActive);
  };

  const showTag = () => {
    setTagActive(!tagActive);
  };

  const showModel = () => {
    setModelActive(!modelActive);
  };

  const showCustomInstructions = () => {
    setCustomInstructionsActive(!customInstructionsActive);
  };

  const showMenu = () => {
    setMenuVisible(!menuVisible);
  };
  const toggleSidebar = () => {
    setSidebarVisible((prevVisible) => !prevVisible);
  };

  useEffect(() => {
    if (!sidebarVisible) {
      setMoreOptions(false);
    }
  }, [sidebarVisible]);

  const closePopUp = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      closeEmailInfoData();
    }
  };

  const closeEmailInfoData = () => {
    setEmailInfoOpen(false);
  };

  const openEmailInfoData = () => {
    setEmailInfoOpen(true);
  };

  //Recieve Answer
  useEffect(() => {
    const socket = io(`${process.env.REACT_APP_QUESTIONS}`, {
      path: "/socket.io",
    });

    socket.on("connect", () => {
      socket.emit("join", { room_id: selectedRoomId });
    });
    socket.on("token", (token: { data: string }) => {
      setResponseStarted(false);
      if (token.data === "end_stream") {
        return;
      }

      const responseMessage: Message = {
        type: "response",
        message: token.data,
      };

      if (token.data === "start_generate_code") {
        printgeneratecode = true;
        currentCodeInputVar++;

        var housebox = document.getElementById(`text-wrap-${currentInputVar}`);

        const generatingcode = document.createElement("div");
        generatingcode.setAttribute(
          "id",
          `code-generating-button-div-${currentCodeInputVar}`
        );
        generatingcode.setAttribute("number", currentCodeInputVar.toString());
        lastgeneratingcode = currentCodeInputVar;
        generatingcode.classList.add("generating-code");
        generatingcode.onclick = function () {
          showHide(currentCodeInputVar);
        };

        const textSpan =
          '<span style="display: inline-block; vertical-align: middle; margin-right: 5px;">GENERATING CODE</span>';
        const arrowSVG =
          '<span style="display: inline-block; vertical-align: middle;"><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M7.41 8.295 12 12.875l4.59-4.58L18 9.705l-6 6-6-6z"></path></svg></span>';

        generatingcode.innerHTML = textSpan + arrowSVG;

        housebox?.appendChild(generatingcode);

        const parentbox = document.createElement("div");
        parentbox.classList.add("parentcodeboxstyle");
        parentbox.setAttribute("id", `parent-code-div-${currentCodeInputVar}`);
        parentbox.style.display = "none";
        housebox?.appendChild(parentbox);

        firsttoken = 1;
      } else if (token.data === "end_generate_code") {
        var generatedcode = document.getElementById(
          `code-generating-button-div-${currentCodeInputVar}`
        );
        if (generatedcode) {
          generatedcode.innerHTML =
            '<span style="display: inline-block; vertical-align: middle; margin-right: 5px;">CODE GENERATED</span>' +
            '<span style="display: inline-block; vertical-align: middle;"><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M7.41 8.295 12 12.875l4.59-4.58L18 9.705l-6 6-6-6z"></path></svg></span>';
          generatedcode.classList.remove("generating-code");
          generatedcode.classList.add("generated-code");
        }
        printgeneratecode = false;
      } else if (token.data === "start_code") {
        printcode = true;
        currentCodeInputVar++;
        var housebox = document.getElementById(`text-wrap-${currentInputVar}`);

        const parentbox = document.createElement("div");
        parentbox.classList.add("parentcodeboxstyle");
        parentbox.setAttribute("id", `parent-code-div-${currentCodeInputVar}`);

        // Create title div for code block
        const titleDiv = document.createElement("div");
        titleDiv.classList.add("titlestyle");

        // Create code content div
        const codeContent = document.createElement("div");
        codeContent.classList.add("codeboxstyle");
        codeContent.setAttribute("id", `code-content-${currentCodeInputVar}`);

        parentbox.appendChild(titleDiv);
        parentbox.appendChild(codeContent);
        housebox?.appendChild(parentbox);
      } else if (token.data === "start_image") {
        // Handle start of image
        printimage = true;
        currentImageInputVar++;
        var housebox = document.getElementById(`text-wrap-${currentInputVar}`);

        const imageContainer = document.createElement("div");
        imageContainer.classList.add("image-container");
        imageContainer.setAttribute(
          "id",
          `image-container-${currentImageInputVar}`
        );
        housebox?.appendChild(imageContainer);

        imageBase64Data = "";
      } else if (token.data === "end_image") {
        printimage = false;
        var imageContainer = document.getElementById(
          `image-container-${currentImageInputVar}`
        );

        if (imageContainer && imageBase64Data) {
          const img = document.createElement("img");
          img.src = `data:image/png;base64,${imageBase64Data}`;
          img.classList.add("generated-image");
          imageContainer.appendChild(img);
        }
      } else if (printimage) {
        imageBase64Data += token.data;
      } else if (token.data === "end_code") {
        printcode = false;

        var codeBox = document.getElementById(
          `parent-code-div-${currentCodeInputVar}`
        );
        if (codeBox) {
          const titleDiv = codeBox.querySelector(".titlestyle");
          if (titleDiv) {
            const copyButton = document.createElement("button");
            copyButton.classList.add("copy-button");
            copyButton.textContent = "Copy";
            copyButton.onclick = () =>
              copyButtonHistory(currentCodeInputVar, currentInputVar);
            titleDiv.appendChild(copyButton);
          }

          const codeContent = codeBox.querySelector(".codeboxstyle");
          if (codeContent) {
            const codeText = codeContent.textContent || "";
            const lines = codeText.split("\n");
            const title = lines.shift() || "javascript";
            const codeWithoutTitle = lines.join("\n");

            ReactDOM.render(
              <SyntaxHighlighter language={title.toLowerCase()} style={dracula}>
                {codeWithoutTitle}
              </SyntaxHighlighter>,
              codeContent
            );
          }
        }
      } else if (printcode) {
        var codeBox = document.getElementById(
          `code-content-${currentCodeInputVar}`
        );
        if (codeBox) {
          const existingContent = codeBox.textContent || "";
          codeBox.textContent = existingContent + token.data;

          if (!codeBox.getAttribute("has-title")) {
            const titleDiv =
              codeBox.parentElement?.querySelector(".titlestyle");
            if (titleDiv) {
              titleDiv.textContent = token.data.split("\n")[0];
            }
            codeBox.setAttribute("has-title", "true");
          }
        }
      } else {
        var div = document.getElementById(`text-wrap-${currentInputVar}`);
        if (div) {
          if (token.data.startsWith("{'source': ")) {
            // Handle source tokens as before
            try {
              const sourceObj = JSON.parse(
                token.data.replace(/'/g, '"').replace(/None/g, "null")
              );
              if (sourceObj.source) {
                const sourceDiv = document.createElement("div");
                sourceDiv.classList.add("source-reference");
                sourceDiv.textContent = `Source: ${sourceObj.source}`;
                div.appendChild(sourceDiv);
              }
            } catch (error) {
              console.error("Error parsing source:", error);
            }
          } else {
            let currentParagraph = div.lastElementChild;
            if (
              !currentParagraph ||
              currentParagraph.classList.contains("parentcodeboxstyle") ||
              currentParagraph.classList.contains("source-reference") ||
              currentParagraph.classList.contains("image-container")
            ) {
              currentParagraph = document.createElement("div");
              currentParagraph.classList.add("markdown-paragraph");
              div.appendChild(currentParagraph);
            }

            const existingContent =
              currentParagraph.getAttribute("data-content") || "";
            const newContent = existingContent + token.data;
            currentParagraph.setAttribute("data-content", newContent);

            ReactDOM.render(
              <ReactMarkdown
                remarkPlugins={[remarkMath, remarkGfm]}
                rehypePlugins={[rehypeMathJax]}
              >
                {newContent}
              </ReactMarkdown>,
              currentParagraph
            );
          }
        }
      }

      setMessageList((prevMessageList) => [
        ...prevMessageList,
        responseMessage,
      ]);
    });

    return () => {
      socket.off("token");
      socket.close();
    };
  }, [clientId, selectedRoomId]);

  useEffect(() => {
    tokenChanged.map((val: any) => {
      var hellodiv = document.getElementById(val);
      if (hellodiv) {
        hellodiv.addEventListener("click", () => setEmailData(val));
        hellodiv.addEventListener("click", () => openEmailInfoData());
      }
    });
  }, [tokenChanged]);

  const showHide = (val: any) => {
    var codess = document.getElementById(`parent-code-div-${val}`);
    if (codess) {
      codess.style.display = codess.style.display === "none" ? "block" : "none";
    }
  };

  useEffect(() => {
    if (loading === false) {
      var generatedcode = document.getElementById(
        `code-generating-button-div-${lastgeneratingcode}`
      );
      if (generatedcode) {
        generatedcode.onclick = function () {
          showHide(generatedcode?.getAttribute("number"));
        };
      }
    }
  }, [loading]);

  //Send Question
  const fetchData = () => {
    setResponseStarted(true);
    setIsScrolledUp(false);
    if (inputMessage.trim() === "") return;

    setLoading(true);

    currentInputVar = messageList.length;

    const newInputMessage: Message = {
      type: "input",
      message: (
        <>
          <div className="flex justify-center mb-5">
            <div className="pb-2 max-w-3xl md:ml-10 max-sm:ml-6 w-full flex flex-row md:gap-6 max-sm:gap-4 items-center">
              <div className="h-full">
                {userData?.data?.profile_picture ? (
                  <img
                    className="rounded-full w-[40px] h-[40px] object-cover"
                    loading="lazy"
                    src={`data:image/jpg;base64,${userData?.data?.profile_picture}`}
                    alt={userData?.data.user_name}
                    style={{ width: 35, height: 35 }}
                  />
                ) : (
                  <img
                    src={ChatUser}
                    style={{ width: 35, height: 35 }}
                    alt={userData?.data.user_name}
                    loading="lazy"
                  />
                )}
              </div>
              <div className=" flex flex-col gap-2 mt-1 w-full">
                <p className=" text-black">{user_name}:</p>
                {selectedImage && (
                  <div className="rounded-md max-sm:pr-4 ">
                    <img
                      src={selectedImage}
                      alt="img"
                      loading="lazy"
                      className=" rounded-md max-h-[400px] "
                    />
                  </div>
                )}
                {inputMessage}
              </div>
            </div>
          </div>
        </>
      ),
    };

    setMessageList((prevMessageList) => [...prevMessageList, newInputMessage]);
    setInputMessage("");
    setPreviousQuestion(inputMessage);
    setSelectedImage(null);

    const formData = new FormData();
    formData.append("message", inputMessage);
    formData.append("user_id", clientId || "");
    formData.append("model_name", selectedModel);
    if (groupSelected) {
      formData.append(
        "group_id",
        selectedAgentId ? selectedAgentId.toString() : ""
      );
    } else {
      formData.append(
        "agent_id",
        selectedAgentId ? selectedAgentId.toString() : ""
      );
    }
    formData.append("tag", selectedTag || "");
    formData.append("debug", debugMode);
    formData.append("regenerate", "0");
    formData.append("room_id", selectedRoomId.toString());
    formData.append("pdf_name", pdf_name || "");
    formData.append("search_engine", (search_engine || 1).toString());
    formData.append("instruction_id", customInstructions || "");
    if (selectedImage) {
      formData.append("image", selectedImage);
    }
    if (file) {
      formData.append("excel", file);
    }

    api
      .post(`${process.env.REACT_APP_ANSWERS}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        setLoading(false);

        const receivedChatDetailsId = response.data.chat_details_id;
        setChatDetailsId(receivedChatDetailsId);

        var imageRotate = document.getElementById(`logo-${currentInputVar}`);

        if (imageRotate === null) {
        } else {
          imageRotate.className = "";
        }
      })
      .catch((error) => {
        console.error("An error occurred:", error);
        setLoading(false);

        var imageRotate = document.getElementById(`logo-${currentInputVar}`);

        if (imageRotate === null) {
        } else {
          imageRotate.className = "";
        }

        var currentdiv = document.getElementById(
          `text-wrap-${currentInputVar}`
        );
        const res = document.createElement("span");
        res.classList.add("errorbox");
        res.innerHTML = error.response.data;
        // const btn = document.createElement("div");
        // btn.addEventListener("click", () => handleRegenrate());
        // btn.innerHTML = "Regenerate";
        // res.appendChild(btn);
        currentdiv?.appendChild(res);
      });

    setPreviousQuestion(inputMessage);
  };

  //Regenerate
  const handleRegenrate = () => {
    if (previousQuestion === "") return;
    if (loading === true) return;

    setLoading(true);

    currentInputVar = messageList.length;

    const newInputMessage: Message = {
      type: "input",
      message: (
        <>
          <div className="flex justify-center">
            <div className=" pb-7 max-w-3xl lg:ml-10 max-md:ml-6 w-full flex flex-row gap-6 items-center">
              {userData?.data?.profile_picture ? (
                <AsyncImage
                  className="rounded-full w-[40px] h-[40px] object-cover"
                  src={`data:image/jpg;base64,${userData?.data?.profile_picture}`}
                  alt={userData?.data.user_name}
                  loading="lazy"
                  style={{ width: 35, height: 35 }}
                  Transition={Blur}
                />
              ) : (
                <AsyncImage
                  src={ChatUser}
                  style={{ width: 35, height: 35 }}
                  Transition={Blur}
                  alt={userData?.data.user_name}
                  loading="lazy"
                />
              )}
              <div className=" flex flex-col gap-2 mt-1 w-full">
                <p className=" text-black">{user_name}:</p>
                {previousQuestion}
              </div>
            </div>
          </div>
        </>
      ),
    };

    setMessageList((prevMessageList) => [...prevMessageList, newInputMessage]);
    setInputMessage("");

    const formData = new FormData();
    formData.append("message", previousQuestion);
    formData.append("user_id", clientId || "");
    formData.append("model_name", selectedModel);
    if (groupSelected) {
      formData.append(
        "group_id",
        selectedAgentId ? selectedAgentId.toString() : ""
      );
    } else {
      formData.append(
        "agent_id",
        selectedAgentId ? selectedAgentId.toString() : ""
      );
    }
    formData.append("tag", selectedTag || "");
    formData.append("debug", debugMode);
    formData.append("regenerate", "1");
    formData.append("room_id", selectedRoomId.toString());
    formData.append("pdf_name", pdf_name || "");
    formData.append("search_engine", (search_engine || 1).toString());
    formData.append("instruction_id", customInstructions || "");

    api
      .post(`${process.env.REACT_APP_ANSWERS}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        setLoading(false);

        const chatDetailsId = response.data.chat_details_id;

        var imageRotate = document.getElementById(`logo-${currentInputVar}`);

        if (imageRotate === null) {
        } else {
          imageRotate.className = "";
        }
      })
      .catch((error) => {
        console.error("An error occurred:", error);
        setLoading(false);

        var imageRotate = document.getElementById(`logo-${currentInputVar}`);

        if (imageRotate === null) {
        } else {
          imageRotate.className = "";
        }

        var currentdiv = document.getElementById(
          `text-wrap-${currentInputVar}`
        );
        const res = document.createElement("span");
        res.classList.add("errorbox");
        res.innerHTML = error.response.data;
        // const btn = document.createElement("div");
        // btn.addEventListener("click", () => handleRegenrate());
        // btn.innerHTML = "Regenerate";
        // res.appendChild(btn);
        currentdiv?.appendChild(res);
      });
  };

  //Copy To Clipboard
  const copyToClipboard = (index: number) => {
    var div = document.getElementById(`copyToClipboard-${index}`);
    if (div === null) {
    } else {
      ReactDOM.render(<HiMiniClipboardDocumentCheck size={25} />, div);
    }

    let finalClip = "";
    messageList.map((item, i) =>
      index < i
        ? item.type === "response"
          ? (finalClip = finalClip + item.message)
          : (index = 10000000000)
        : null
    );

    finalClip = finalClip.replace(/start_code/g, "").replace(/end_code/g, "");

    const unsecuredCopyToClipboard = (text: string) => {
      const textArea = document.createElement("textarea");
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        document.execCommand("copy");
      } catch (err) {
        console.error("Unable to copy to clipboard", err);
      }
      document.body.removeChild(textArea);
    };

    if (window.isSecureContext && navigator.clipboard) {
      navigator.clipboard
        .writeText(finalClip)
        .then(() => {
          toast.success("Copied to clipboard!", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            theme: "dark",
          });
          setTimeout(() => {
            ReactDOM.render(<AiOutlineCopy size={18} />, div);
          }, 2000);
        })
        .catch((error) => {
          console.error("Failed to copy text to clipboard: ", error);
        });
    } else {
      unsecuredCopyToClipboard(finalClip);
      toast.success("Copied to clipboard!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
    }
  };

  const copyToClipboardHistory = (index: number) => {
    var div = document.getElementById(`copyToClipboard-history-${index}`);
    if (div === null) {
    } else {
      ReactDOM.render(<HiMiniClipboardDocumentCheck size={25} />, div);
    }
    let finalClip = "";
    finalClip = historyData[index].answer;
    finalClip = finalClip.replace(/start_code/g, "").replace(/end_code/g, "");
    const unsecuredCopyToClipboard = (text: string) => {
      const textArea = document.createElement("textarea");
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        document.execCommand("copy");
      } catch (err) {
        console.error("Unable to copy to clipboard", err);
      }
      document.body.removeChild(textArea);
    };
    if (window.isSecureContext && navigator.clipboard) {
      navigator.clipboard.writeText(finalClip).then(() => {
        setTimeout(() => {
          ReactDOM.render(<AiOutlineCopy size={18} />, div);
        }, 2000);
      });
    } else {
      unsecuredCopyToClipboard(finalClip);
    }
    toast.success("Copied to clipboard!", {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      theme: "dark",
    });
  };

  //Scroll To Bottom
  const scrollToBottom = () => {
    if (!isScrolledUp) {
      const div = document.getElementById("div-of-scroll");
      if (div === null) {
      } else {
        div.scrollTo({
          top: div.scrollHeight,
          behavior: "smooth",
        });
      }
    }
  };

  useEffect(() => {
    scrollToBottom();
    const handleScroll = () => {
      const div = chatContainerRef.current;

      setIsScrolledUp(true);
      if (div && div.scrollTop + div.clientHeight > div.scrollHeight - 5) {
        setIsScrolledUp(false);
      }
    };

    const div = chatContainerRef.current;
    if (div) {
      div.addEventListener("scroll", handleScroll);

      return () => {
        div.removeEventListener("scroll", handleScroll);
      };
    }
  }, [messageList, historyData]);

  //Like Like
  const handleLike = async (index: number) => {
    if (!chatDetailsId) return;
    var likeDiv = document.getElementById(`like-${index}`);
    if (likeDiv === null) return;

    ReactDOM.render(<AiFillLike size={18} />, likeDiv);

    var disLikeDiv = document.getElementById(`disLike-${index}`);
    if (disLikeDiv) {
      ReactDOM.render(<AiOutlineDislike size={18} />, disLikeDiv);
    }

    try {
      await api.post(`${process.env.REACT_APP_FEEDBACK}`, {
        chat_details_id: chatDetailsId,
        like_status: "1",
      });

      toast.success("Thank you for your feedback!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
    } catch (error) {
      console.error("Error submitting feedback:", error);
      toast.error("Failed to submit feedback");
    }
  };

  const handleDisLike = (index: number) => {
    if (!chatDetailsId) return;
    var likeDiv = document.getElementById(`like-${index}`);
    if (likeDiv === null) return;

    ReactDOM.render(<AiOutlineLike size={18} />, likeDiv);

    var disLikeDiv = document.getElementById(`disLike-${index}`);
    if (disLikeDiv) {
      ReactDOM.render(<AiFillDislike size={18} />, disLikeDiv);
    }

    setCurrentFeedbackId(chatDetailsId);
    setShowDislikeFeedback(true);
  };

  const handleLikeHistory = async (index: number) => {
    var likeDiv = document.getElementById(`like-history-${index}`);
    if (likeDiv === null) {
    } else {
      ReactDOM.render(<AiFillLike size={18} />, likeDiv);
    }

    var disLikeDiv = document.getElementById(`disLike-history-${index}`);
    if (disLikeDiv === null) {
    } else {
      ReactDOM.render(<AiOutlineDislike size={18} />, disLikeDiv);
    }

    try {
      await api.post(`${process.env.REACT_APP_FEEDBACK}`, {
        chat_details_id: historyData[index].chat_details_id,
        like_status: "1",
      });

      toast.success("Your feedback has been noted!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
    } catch (error) {
      console.error("Error submitting feedback:", error);
      toast.error("Failed to submit feedback");
    }
  };
  const handleDisLikeHistory = (index: number) => {
    var likeDiv = document.getElementById(`like-history-${index}`);
    if (likeDiv === null) {
    } else {
      ReactDOM.render(<AiOutlineLike size={18} />, likeDiv);
    }
    var disLikeDiv = document.getElementById(`disLike-history-${index}`);
    if (disLikeDiv === null) {
    } else {
      ReactDOM.render(<AiFillDislike size={18} />, disLikeDiv);
    }

    setCurrentFeedbackId(historyData[index].chat_details_id);
    setShowDislikeFeedback(true);
  };

  useEffect(() => {
    const apiUrl = `${process.env.REACT_APP_HISTORY}${selectedRoomId}`;

    api
      .get(apiUrl)
      .then((response) => {
        const data = response.data;
        setHistoryData(data);
        setInputMessage("");
        currentInputVar = response.data.length - 1;
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, [selectedRoomId]);

  const copyButtonHistory = (codeInputVar: number, inputVar: number) => {
    let codeContent = document.getElementById(`code-content-${codeInputVar}`);
    if (!codeContent) {
      codeContent = document.getElementById(
        `generate-code-content-${codeInputVar}`
      );
    }

    if (codeContent && codeContent.textContent) {
      const textToCopy = codeContent.textContent
        .split("\n")
        .slice(1)
        .join("\n")
        .trim();
      navigator.clipboard
        .writeText(textToCopy)
        .then(() => {
          const copyButton =
            document.querySelector(
              `#parent-code-div-${codeInputVar} .copy-button`
            ) ||
            document.querySelector(
              `#parent-generate-code-div-${codeInputVar} .copy-button`
            );

          if (copyButton instanceof HTMLButtonElement) {
            const originalText = copyButton.textContent;
            copyButton.textContent = "Copied!";
            setTimeout(() => {
              copyButton.textContent = originalText;
            }, 2000);
          }
        })
        .catch((err) => {
          console.error("Failed to copy text: ", err);
          alert("Failed to copy code to clipboard");
        });
    }
  };

  const handleButtonClick = (e: React.MouseEvent<HTMLDivElement>) => {
    setMoreOptions(false);
  };

  const toggleMoreOptionsButton = () => {
    setMoreOptions((prevMoreOptions) => !prevMoreOptions);
  };

  const handleStopGeneration = async () => {
    try {
      api.post(`${process.env.REACT_APP_STOP_GENERATION}${selectedRoomId}`, {
        search_engine: search_engine,
        model_name: selectedModel,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      var imageRotate = document.getElementById(`logo-${currentInputVar}`);

      if (imageRotate === null) {
      } else {
        imageRotate.className = "";
      }
    } catch (error) {}

    printcode = false;
  };

  const setPdfName = (val: string) => {
    pdf_name = val;
  };
  const setSearchEngine = (val: string) => {
    search_engine = val;
  };

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result as string;
        setSelectedImage(base64String);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRemoveImage = () => {
    setSelectedImage(null);
  };

  useEffect(() => {
    const shuffledArray = [...examples].sort(() => Math.random() - 0.5);
    const subset = shuffledArray.slice(0, 6);
    setShuffledExamples(subset);
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setMessageIndex((prevIndex) => (prevIndex + 1) % Thinking.length);
    }, 3000);

    return () => clearTimeout(timeoutId);
  }, [messageIndex]);

  const [groupSelected, setGroupSelected] = useState(false);

  const handleGroupSelectedChangeTrue = () => {
    setGroupSelected(true);
  };

  const handleGroupSelectedChangeFalse = () => {
    setGroupSelected(false);
  };

  const openDeleteAllButton = () => {
    setOpenDeleteAll(true);
  };

  const closeDeleteAllButton = () => {
    setOpenDeleteAll(false);
  };

  const handleDeleteButtonClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      closeDeleteAllButton();
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0];
    setFile(selectedFile || null);
  };

  const getFileLogo = (fileType: any) => {
    switch (fileType) {
      case "xls":
      case "xlsx":
      case "application/vnd.ms-excel":
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        return <PiMicrosoftExcelLogoLight size={25} />;
      case "csv":
      case "text/csv":
        return <BsFiletypeCsv size={25} />;
      default:
        return <CiFileOn size={25} />;
    }
  };

  return (
    <section className="flex flex-row bg-white w-screen h-screen text-zinc-500">
      {/* Sidebar */}
      <aside
        className={` relative ${
          sidebarVisible ? "lg:w-[18%] w-screen p-4" : "w-[0]"
        } h-screen bg-[#000024] transition-all border-r-[1px] `}
      >
        <div className=" flex flex-col gap-2">
          <div className="flex items-center gap-2">
            <button
              onClick={() => {
                setHistoryData([]);
                setMessageList([]);
                setSelectedAgent(mindAgentData && mindAgentData[0].agent);
                setSelectedModel("boxMind");
                setSelectedAgentId(mindAgentData && mindAgentData[0].id);
                setSelectedTag("");
                setSelectedTagName("");
                setPdfName("");
                setInputMessage("");
                setGroupSelected(false);
                setSearchEngine("0");
                setSelectedRoomId(Math.round(Math.random() * 1e9));
                setCustomInstructions(null);
                setCustomInstructionsName("");
                setFile(null);
              }}
              type="button"
              className={`w-full lg:h-[30px] h-[40px] rounded-md bg-[#20144C] text-white hover:bg-[#45328b] duration-300 ${
                sidebarVisible ? "" : "hidden"
              }`}
            >
              + New Chat
            </button>
            <div className={`relative ${sidebarVisible ? "" : " p-4"}`}>
              <button
                type="button"
                onClick={toggleSidebar}
                className={`lg:h-[30px] h-[40px] px-2 hover:bg-[#45328b] text-white duration-300 rounded-md ${
                  sidebarVisible ? " bg-[#20144C] text-white " : "bg-[#20144C]"
                }`}
              >
                <FiSidebar size={20} />
              </button>
            </div>
          </div>
        </div>
        <div
          onClick={handleButtonClick}
          className="h-screen mt-5 overflow-y-scroll custom-scrollbar-chat"
          style={{ maxHeight: "calc(100vh - 180px)" }}
        >
          {/* Chat history */}
          <ChatHistory
            setSelectedModel={setSelectedModel}
            setSelectedAgent={setSelectedAgent}
            setSelectedRoomId={setSelectedRoomId}
            selectedRoomId={selectedRoomId}
            setMessageList={setMessageList}
            setHistoryMainData={setHistoryData}
            messageList={messageList}
            setPdfName={setPdfName}
            setSearchEngine={setSearchEngine}
            loading={loading}
            toggleSidebar={toggleSidebar}
            setSelectedAgentId={setSelectedAgentId}
            setSelectedTag={setSelectedTag}
            setSelectedTagName={setSelectedTagName}
            refreshRooms={refreshRooms}
            setGroupSelected={setGroupSelected}
            setFile={setFile}
            AgentName={mindAgentData && mindAgentData[0].agent}
            AgentID={mindAgentData && mindAgentData[0].id}
          />
        </div>

        <div
          className={`pt-6 border-t mt-5 border-[#C5D4EB] flex flex-row items-center justify-between ${
            sidebarVisible ? "" : "hidden"
          }`}
        >
          <div>
            <a href="https://boxmind.ai/" target="_blank" rel="noreferrer">
              <img
                src={Okation}
                width={120}
                height={40}
                alt="OkationAi"
                loading="lazy"
              />
            </a>
          </div>
          <div>
            <button
              onClick={toggleMoreOptionsButton}
              className=" hover:bg-[#45328b] text-white duration-300 p-3 rounded-lg mr-1"
            >
              <BsThreeDots />
            </button>
          </div>
        </div>
        {/* Start More Options */}
        {moreOptions && (
          <div className="z-50 absolute bottom-[110px] w-full pr-8 ">
            <div className="bg-[#061118] rounded-lg shadow-2xl ">
              <div className=" flex flex-col ">
                <p className="p-4 flex justify-center font-bold">
                  More Options
                </p>
                <div className=" border-b" />
                <div className="p-4 flex flex-col gap-3">
                  <button
                    onClick={openDeleteAllButton}
                    className=" bg-red-800 rounded-md text-white p-2 hover:bg-red-900 duration-300 w-full"
                  >
                    Delete All Rooms
                  </button>

                  <button
                    disabled={messageList.length > 0 || historyData.length > 0}
                    onClick={showModel}
                    className={` bg-[#EDEAF8] rounded-md p-2 w-full ${
                      messageList.length > 0 || historyData.length > 0
                        ? ""
                        : "hover:bg-[#b5b1c4] duration-300"
                    }`}
                  >
                    AI Model
                  </button>

                  <button
                    disabled={messageList.length > 0 || historyData.length > 0}
                    onClick={showAgent}
                    className={` bg-[#EDEAF8] rounded-md p-2 w-full ${
                      messageList.length > 0 || historyData.length > 0
                        ? ""
                        : "hover:bg-[#b5b1c4] duration-300"
                    }`}
                  >
                    Data Agent
                  </button>

                  {!groupSelected && (
                    <button
                      onClick={showTag}
                      className="bg-[#EDEAF8] rounded-md p-2 w-full hover:bg-[#b5b1c4] duration-300"
                    >
                      Sub Data Agent
                    </button>
                  )}

                  <button
                    onClick={showCustomInstructions}
                    className="bg-[#EDEAF8] rounded-md p-2 w-full hover:bg-[#b5b1c4] duration-300"
                  >
                    Prompt Instruction
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        {/* End More Options */}
      </aside>

      {/* Main Chat */}
      <div
        onClick={handleButtonClick}
        className={`${sidebarVisible ? "w-[87%] max-lg:hidden" : "w-[100%]"}`}
      >
        {/* Response Section */}
        {historyData?.length > 0 ? (
          <>
            <div
              className={`flex justify-center gap-5 items-center lg:justify-start px-4 py-2 ${
                sidebarVisible ? "" : " lg:ml-[100px]"
              } `}
            >
              <Link to={"/"}>
                <img
                  src={Turbo}
                  width={130}
                  alt="boxMind"
                  loading="lazy"
                  title="Go back to Home Page"
                />
              </Link>
              {historyData[0]?.pdf_name && (
                <p className="mb-1">{historyData[0]?.pdf_name}</p>
              )}
            </div>
            <div className="flex justify-center h-[85%]">
              <div
                ref={chatContainerRef}
                className="w-full h-[90%] overflow-y-scroll custom-scrollbar pt-8"
                id="div-of-scroll"
              >
                {historyData?.map((data, index) => (
                  <div key={index}>
                    <div className="flex justify-center mb-5">
                      <div className="pb-2 max-w-3xl lg:ml-10 max-sm:ml-6 w-full flex flex-row lg:gap-6 max-sm:gap-4 items-center">
                        <div className="h-full">
                          {userData?.data?.profile_picture ? (
                            <AsyncImage
                              className="rounded-full w-[40px] h-[40px] object-cover"
                              loading="lazy"
                              src={`data:image/jpg;base64,${userData?.data?.profile_picture}`}
                              alt={userData?.data.user_name}
                              style={{ width: 35, height: 35 }}
                              Transition={Blur}
                            />
                          ) : (
                            <AsyncImage
                              src={ChatUser}
                              loading="lazy"
                              style={{ width: 35, height: 35 }}
                              Transition={Blur}
                              alt={userData?.data.user_name}
                            />
                          )}
                        </div>
                        <div className=" flex flex-col gap-2 mt-1 w-full">
                          <p className=" text-black">{user_name}:</p>
                          {data?.image && (
                            <div
                              onClick={() => handleImageClick(data?.image)}
                              className="rounded-md max-sm:pr-4"
                            >
                              <img
                                src={data?.image}
                                alt="img"
                                loading="lazy"
                                className="rounded-md max-h-[400px] "
                              />
                            </div>
                          )}
                          <p>{data?.question}</p>
                        </div>
                      </div>
                      {expandedImage && data?.image && (
                        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-30 flex justify-center items-center z-40">
                          <div>
                            <img
                              src={expandedImage}
                              alt="Expanded img"
                              className="max-w-full max-h-full"
                            />
                          </div>
                          <div className=" fixed top-4 right-4 z-50">
                            <button onClick={() => handleCloseModal()}>
                              <AiOutlineClose
                                size={35}
                                className=" text-white"
                              />
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="flex justify-center mb-5">
                      <div
                        className={`pb-7 max-w-3xl lg:ml-10 max-sm:ml-6 w-full flex flex-row lg:gap-6 max-sm:gap-4 items-center`}
                      >
                        <div className="h-full">
                          <img
                            src={Logo}
                            alt="TurboMind"
                            loading="lazy"
                            width={35}
                            height={35}
                            className=""
                          />
                        </div>
                        <div className="flex flex-col gap-2 mt-1 w-full">
                          <p className="text-black">boxMind:</p>
                          <div>
                            {data.answer
                              ?.split("start_code")
                              .map((part, partIndex) => {
                                if (partIndex === 0) {
                                  return (
                                    <React.Fragment key={partIndex}>
                                      <div className="markdown-paragraph whitespace-break-spaces">
                                        <ReactMarkdown
                                          remarkPlugins={[
                                            remarkMath,
                                            remarkGfm,
                                          ]}
                                          rehypePlugins={[rehypeMathJax]}
                                        >
                                          {part}
                                        </ReactMarkdown>
                                      </div>
                                    </React.Fragment>
                                  );
                                } else {
                                  const [content, rest] =
                                    part?.split("end_code");
                                  const language = content
                                    .split("\n")[0]
                                    .trim();

                                  return (
                                    <React.Fragment key={partIndex}>
                                      <div
                                        key={partIndex}
                                        className={`parentcodeboxstylehistory whitespace-break-spaces`}
                                      >
                                        <div key={0} className="titlestyle">
                                          <span>{language}</span>{" "}
                                          <button
                                            className="copy-button"
                                            id={`copy-button-${partIndex}-${index}`}
                                            onClick={() => {
                                              const codeContent =
                                                document.getElementById(
                                                  `code-div-history-${partIndex}-${index}`
                                                )?.textContent;
                                              if (codeContent) {
                                                navigator.clipboard
                                                  .writeText(codeContent.trim())
                                                  .then(() => {
                                                    const button =
                                                      document.getElementById(
                                                        `copy-button-${partIndex}-${index}`
                                                      );
                                                    if (button) {
                                                      const originalText =
                                                        button.textContent;
                                                      button.textContent =
                                                        "Copied!";
                                                      setTimeout(() => {
                                                        button.textContent =
                                                          originalText;
                                                      }, 2000);
                                                    }
                                                  })
                                                  .catch((err) => {
                                                    console.error(
                                                      "Failed to copy text: ",
                                                      err
                                                    );
                                                    alert(
                                                      "Failed to copy code to clipboard"
                                                    );
                                                  });
                                              }
                                            }}
                                          >
                                            Copy
                                          </button>
                                        </div>
                                        <div className="overflow-x-scroll w-full">
                                          <SyntaxHighlighter
                                            language={language}
                                            style={dracula}
                                            className="codeboxstyle whitespace-break-spaces"
                                            id={`code-div-history-${partIndex}-${index}`}
                                          >
                                            {content
                                              ?.split("\n")
                                              .map((cont, ind) => {
                                                if (ind === 0) {
                                                  return "";
                                                } else if (ind === 1) {
                                                  return cont;
                                                } else {
                                                  return "\n" + cont;
                                                }
                                              })
                                              .join("")}
                                          </SyntaxHighlighter>
                                        </div>
                                      </div>
                                      <div className="markdown-paragraph whitespace-break-spaces">
                                        <ReactMarkdown
                                          remarkPlugins={[
                                            remarkMath,
                                            remarkGfm,
                                          ]}
                                          rehypePlugins={[rehypeMathJax]}
                                        >
                                          {rest}
                                        </ReactMarkdown>
                                      </div>
                                    </React.Fragment>
                                  );
                                }
                              })}
                          </div>
                          {data.sources?.split(";")[0] === "" ? null : (
                            <>
                              <br />
                              <div className="max-lg:max-w-[220px] overflow-x-scroll">
                                {data?.sources
                                  ?.split(";")
                                  ?.map((source, index) =>
                                    source.startsWith("Subject: ") ? (
                                      <div
                                        onClick={() => {
                                          setEmailData(source);
                                          openEmailInfoData();
                                        }}
                                        className="cursor-pointer underline mb-1 "
                                      >
                                        {source
                                          .replace("Subject: ", "")
                                          .replace("hrsrc'}", "")
                                          .replace(`hrsrc"}`, "")
                                          .slice(
                                            0,
                                            source
                                              .replace("Subject: ", "")
                                              .replace("hrsrc'}", "")
                                              .replace(`hrsrc"}`, "")
                                              .indexOf(", Sender:")
                                          )}
                                      </div>
                                    ) : (
                                      <div key={index}>
                                        <a
                                          target="_blank"
                                          className="underline duration-300 hover:text-[gray]"
                                          href={`file:///C:/Users/abdom/Documents/OKation/turboMind/notDuplicate/${
                                            source.endsWith("}")
                                              ? source
                                                  .replace(
                                                    "/home/bizon/uploaded_files/",
                                                    ""
                                                  )
                                                  .slice(0, -10)
                                              : source.replace(
                                                  "/home/bizon/uploaded_files/",
                                                  ""
                                                )
                                          }`}
                                          rel="noreferrer"
                                        >
                                          {source.replace(
                                            "/home/bizon/uploaded_files/",
                                            ""
                                          )}
                                        </a>
                                        <br />
                                      </div>
                                    )
                                  )}
                              </div>
                            </>
                          )}
                          {data?.debug === 1 && (
                            <div className=" flex flex-col">
                              <br />
                              <div className=" flex flex-row gap-1">
                                <p>Faithfulness: </p>
                                <span>{data?.faithfulness}</span>
                              </div>
                              <div className=" flex flex-row gap-1">
                                <p>Answer Relevancy: </p>
                                <span>{data?.answer_relevancy}</span>
                              </div>
                              <div className=" flex flex-row gap-1">
                                <p>Context Relevancy: </p>
                                <span>{data?.context_relevancy}</span>
                              </div>
                            </div>
                          )}
                          {loading && currentInputVar === index ? null : (
                            <div className={`h-full mt-2 max-sm:pr-4`}>
                              <button
                                type="button"
                                onClick={() => copyToClipboardHistory(index)}
                                className="-mt-3 rounded-md text-sm hover:text-gray-400 duration-300"
                                id={`copyToClipboard-history-${index}`}
                              >
                                <AiOutlineCopy size={18} />
                              </button>
                              <button
                                type="button"
                                onClick={() => handleLikeHistory(index)}
                                className={`ml-2 mb-3 rounded-md text-sm duration-300`}
                                id={`like-history-${index}`}
                                disabled={!!data?.dislike || !!data?.like}
                              >
                                {data?.like ? (
                                  <AiFillLike size={18} />
                                ) : (
                                  <AiOutlineLike size={18} />
                                )}
                              </button>
                              <button
                                type="button"
                                onClick={() => handleDisLikeHistory(index)}
                                className={`ml-2 rounded-md text-sm duration-300`}
                                id={`disLike-history-${index}`}
                                disabled={!!data?.dislike || !!data?.like}
                              >
                                {data?.dislike ? (
                                  <AiFillDislike size={18} />
                                ) : (
                                  <AiOutlineDislike size={18} />
                                )}
                              </button>
                              {index === currentInputVar && (
                                <button
                                  type="button"
                                  onClick={() => handleRegenrate()}
                                  className="ml-2 rounded-md text-sm hover:text-gray-400 duration-300"
                                  id={`regenerate-${index}`}
                                >
                                  <HiOutlineRefresh size={18} />
                                </button>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                <>
                  {messageList?.map((item, i) =>
                    item.type === "input" ? (
                      <div key={i}>
                        {item?.message}
                        <div className="flex justify-center mb-5">
                          <div className="pb-2 max-w-3xl md:ml-10 max-sm:ml-6 w-full flex flex-row md:gap-6 max-sm:gap-4 items-center">
                            <div className="h-full">
                              <img
                                src={Logo}
                                alt="TurboMind"
                                width={35}
                                loading="lazy"
                                height={35}
                                className=" animate-spin"
                                id={`logo-${i}`}
                              />
                            </div>
                            <div className="  flex flex-col gap-2 mt-1 w-full whitespace-break-spaces">
                              <p className=" text-black">boxMind:</p>
                              {responseStarted ? (
                                currentInputVar === i ? (
                                  selectedAgent === "Google" ||
                                  selectedAgent === "Bing" ||
                                  selectedAgent === "Web_Search" ? (
                                    <div className=" animate-pulse">
                                      {Thinking[messageIndex]}
                                    </div>
                                  ) : null
                                ) : null
                              ) : null}
                              <p id={`text-wrap-${i}`}></p>
                              {loading && currentInputVar === i ? null : (
                                <div className={`h-full mt-2 max-sm:pr-4`}>
                                  <button
                                    type="button"
                                    onClick={() => copyToClipboard(i)}
                                    className="-mt-3 rounded-md text-sm hover:text-gray-400 duration-300"
                                    id={`copyToClipboard-${i}`}
                                  >
                                    <AiOutlineCopy size={18} />
                                  </button>
                                  <button
                                    type="button"
                                    onClick={() => handleLike(i)}
                                    className="ml-2 mb-3 rounded-md text-sm hover:text-gray-400 duration-300"
                                    id={`like-${i}`}
                                  >
                                    <AiOutlineLike size={18} />
                                  </button>
                                  <button
                                    type="button"
                                    onClick={() => handleDisLike(i)}
                                    className="ml-2 rounded-md text-sm hover:text-gray-400 duration-300"
                                    id={`disLike-${i}`}
                                  >
                                    <AiOutlineDislike size={18} />
                                  </button>
                                  {i === currentInputVar && (
                                    <button
                                      type="button"
                                      onClick={() => handleRegenrate()}
                                      className="ml-2 rounded-md text-sm hover:text-gray-400 duration-300"
                                      id={`regenerate-${i}`}
                                    >
                                      <HiOutlineRefresh size={18} />
                                    </button>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null
                  )}
                </>
              </div>
            </div>
          </>
        ) : messageList?.length > 0 ? (
          <>
            <div
              className={`flex justify-center gap-5 items-center lg:justify-start px-4 py-2 ${
                sidebarVisible ? "" : " lg:ml-[100px]"
              } `}
            >
              <Link to={"/"}>
                <img
                  src={Turbo}
                  width={130}
                  alt="boxMind"
                  loading="lazy"
                  title="Go back to Home Page"
                />
              </Link>
              {pdf_name && <p className="mb-1">{pdf_name}</p>}
            </div>
            <div className=" flex justify-center h-[85%]">
              <div
                ref={chatContainerRef}
                className="w-full h-[90%] overflow-y-scroll custom-scrollbar pt-8"
                id="div-of-scroll"
              >
                {messageList?.map((item, i) =>
                  item.type === "input" ? (
                    <div key={i}>
                      {item.message}
                      <div className="flex justify-center mb-5">
                        <div className="pb-7 max-w-3xl md:ml-10 max-sm:ml-6 w-full flex flex-row md:gap-6 max-sm:gap-4 items-center">
                          <div className="h-full">
                            <img
                              src={Logo}
                              loading="lazy"
                              alt="TurboMind"
                              width={35}
                              height={35}
                              className=" animate-spin"
                              id={`logo-${i}`}
                            />
                          </div>
                          <div className="  flex flex-col gap-2 mt-1 w-full whitespace-break-spaces">
                            <p className=" text-black">boxMind:</p>
                            {responseStarted ? (
                              currentInputVar === i ? (
                                selectedAgent === "Google" ||
                                selectedAgent === "Bing" ||
                                selectedAgent === "Web_Search" ? (
                                  <div className=" animate-pulse">
                                    {Thinking[messageIndex]}
                                  </div>
                                ) : null
                              ) : null
                            ) : null}
                            <p id={`text-wrap-${i}`}></p>
                            {loading && currentInputVar === i ? null : (
                              <div className={`h-full mt-2 max-sm:pr-4`}>
                                <button
                                  type="button"
                                  onClick={() => copyToClipboard(i)}
                                  className="-mt-3 rounded-md text-sm hover:text-gray-400 duration-300"
                                  id={`copyToClipboard-${i}`}
                                >
                                  <AiOutlineCopy size={18} />
                                </button>
                                <button
                                  type="button"
                                  onClick={() => handleLike(i)}
                                  className="ml-2 mb-3 rounded-md text-sm hover:text-gray-400 duration-300"
                                  id={`like-${i}`}
                                >
                                  <AiOutlineLike size={18} />
                                </button>
                                <button
                                  type="button"
                                  onClick={() => handleDisLike(i)}
                                  className="ml-2 rounded-md text-sm hover:text-gray-400 duration-300"
                                  id={`disLike-${i}`}
                                >
                                  <AiOutlineDislike size={18} />
                                </button>
                                {i === currentInputVar && (
                                  <button
                                    type="button"
                                    onClick={() => handleRegenrate()}
                                    className="ml-2 rounded-md text-sm hover:text-gray-400 duration-300"
                                    id={`regenerate-${i}`}
                                  >
                                    <HiOutlineRefresh size={18} />
                                  </button>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null
                )}
              </div>
            </div>
          </>
        ) : (
          // Response Active
          // Response Inactive
          <div className=" flex justify-center mx-auto content-center lg:pt-10 pt-2">
            <div className="h-[85%] flex flex-col gap-20 justify-center items-center">
              <div className=" flex justify-center items-center">
                <img
                  src={Turbo}
                  width={280}
                  height={50}
                  loading="lazy"
                  alt="TurboMind"
                  className=" max-md:w-[250px]"
                />
              </div>
              <div className="flex flex-col max-lg:px-4">
                {pdf_name ? (
                  <p className=" flex justify-center items-center lg:text-[20px]">
                    {pdf_name}
                  </p>
                ) : (
                  <AnimatePresence>
                    <motion.div
                      initial={{ opacity: 0, y: 4 }}
                      whileInView={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -1 }}
                      viewport={{ once: false }}
                      transition={{ duration: 0.3 }}
                      className="grid justify-center grid-cols-2 gap-5"
                    >
                      {shuffledExamples?.map((item, index) => (
                        <div
                          key={index}
                          className="mt-4 p-4 bg-[#EDEAF8] rounded-md cursor-pointer max-w-[1740px] max-md:text-[12px] text-center hover:bg-[#b5b1c4] duration-300"
                          onClick={() => {
                            setInputMessage(item);
                            inputRef.current?.focus();
                          }}
                        >
                          {item}
                        </div>
                      ))}
                    </motion.div>
                  </AnimatePresence>
                )}
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Input Section */}
      <div
        className={` ${
          sidebarVisible ? "md:ml-[155px] max-lg:hidden" : ""
        } fixed left-1/2 -translate-x-1/2 bottom-2 md:w-[43rem] w-full max-md:px-4 flex flex-col`}
      >
        {/* Images Start */}

        {selectedImage && (
          <div className="flex bg-white rounded-md p-4">
            <div className="relative">
              <img
                src={selectedImage}
                loading="lazy"
                alt={`Selected pic`}
                className="w-[60px] h-[60px] rounded-md object-cover"
              />
              <button
                className="absolute top-1 right-1 p-1 bg-red-600 hover:bg-red-700 duration-300 text-white rounded-full"
                onClick={handleRemoveImage}
              >
                <MdOutlineCancel size={10} />
              </button>
            </div>
          </div>
        )}
        {file && (
          <div className="flex flex-row gap-2 items-center rounded-2xl border-[#EDEAF8]s p-2 bg-white border-t border-r border-l">
            <p className="pl-4">{getFileLogo(file.type)}</p>
            <p>{file.name}</p>
          </div>
        )}
        {/* Images End */}
        <div className=" flex flex-row gap-2 ">
          <div className=" w-full flex flex-row items-center bg-white rounded-2xl border border-gray-300">
            {/* Upload Image Start */}
            <div>
              <label htmlFor="imageInput" className="cursor-pointer">
                <input
                  type="file"
                  id="imageInput"
                  accept=".png, .jpg, .jpeg"
                  className="hidden"
                  multiple={false}
                  onChange={handleImageChange}
                />
                <div className=" pl-4 rounded-md cursor-pointer">
                  <GoImage size={20} />
                </div>
              </label>
            </div>
            {selectedModel === "CodeLlama" && (
              <div>
                <label htmlFor="exelInput" className="cursor-pointer">
                  <input
                    type="file"
                    id="exelInput"
                    accept=".xls,.xlsx,.csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/csv"
                    className="hidden"
                    multiple={false}
                    onChange={handleFileChange}
                  />
                  <div className=" pl-4 rounded-md cursor-pointer">
                    <CgAttachment size={20} />
                  </div>
                </label>
              </div>
            )}
            {/* Upload Image End */}
            <textarea
              autoFocus
              ref={inputRef}
              value={inputMessage}
              onKeyDown={(e) => {
                if (e.key === "Enter" && !loading && !e.shiftKey) {
                  fetchData();
                  e.preventDefault();
                }
              }}
              onChange={(e) => setInputMessage(e.target.value)}
              className={`w-full mx-auto  pr-16 bg-white outline-none p-4 resize-none rounded-2xl ${
                inputMessage.length <= 134
                  ? "h-[55px] custom-scrollbar"
                  : "h-[140px] custom-scrollbar-chat"
              }`}
              placeholder="Send a message"
              maxLength={1000}
            />
            {loading ? (
              <div className=" flex justify-end py-3">
                <div
                  onClick={handleStopGeneration}
                  className=" bg-[#EDEAF8] border-[#EDEAF8] p-1 rounded-md hover:bg-[#b5b1c4] duration-300 cursor-pointer mr-3"
                >
                  <CiStop1 size={20} />
                </div>
              </div>
            ) : (
              <button
                type="button"
                onClick={() => fetchData()}
                disabled={loading || inputMessage.length === 0}
                className={` mr-3  ${
                  inputMessage.length !== 0
                    ? "bg-[#135991] text-white p-1 rounded-md"
                    : "p-1"
                } ${loading ? " bg-[#163752] rounded-md" : ""}`}
              >
                <VscSend size={20} />
              </button>
            )}
          </div>
        </div>
        <div>
          <span
            className={`flex justify-end  ${
              inputMessage.length === 1000 ? "text-[red]" : "text-gray-500"
            }`}
          >
            {inputMessage.length}/1000
          </span>
        </div>
      </div>

      {openDeleteAll && (
        <DeleteAllRooms
          handleDeleteButtonClick={handleDeleteButtonClick}
          clientId={clientId}
          setSelectedRoomId={setSelectedRoomId}
          setMessageList={setMessageList}
          setHistoryData={setHistoryData}
          setRefreshRooms={setRefreshRooms}
          setOpenDeleteAll={setOpenDeleteAll}
          setMoreOptions={setMoreOptions}
          setGroupSelected={setGroupSelected}
          setFile={setFile}
        />
      )}

      {/* Model Component */}
      <Model
        selectedModel={selectedModel}
        setSelectedModel={setSelectedModel}
        showModel={showModel}
        modelActive={modelActive}
        onSelectModel={setSelectedModel}
      ></Model>

      {/* Agent Component */}
      <Agent
        selectedAgent={selectedAgent}
        showAgent={showAgent}
        setSelectedAgent={setSelectedAgent}
        agentActive={agentActive}
        onSelectAgent={setSelectedAgent}
        setSelectedAgentId={setSelectedAgentId}
        setCanResetTag={setCanResetTag}
        handleGroupSelectedChangeTrue={handleGroupSelectedChangeTrue}
        handleGroupSelectedChangeFalse={handleGroupSelectedChangeFalse}
      ></Agent>

      {/* Tag Component */}
      <Tag
        selectedTag1={selectedTag}
        showTag={showTag}
        tagActive={tagActive}
        onSelectTag={setSelectedTag}
        selectedAgentId={selectedAgentId}
        setSelectedTagName={setSelectedTagName}
      ></Tag>

      <CustomInstructions
        showCustomInstructions={showCustomInstructions}
        customInstructions={customInstructions}
        setCustomInstructions={setCustomInstructions}
        customInstructionsActive={customInstructionsActive}
        setCustomInstructionsName={setCustomInstructionsName}
      ></CustomInstructions>

      {isEmailInfoOpen && (
        <EmailInfo
          closePopUp={closePopUp}
          closeSyncData={closeEmailInfoData}
          emailData={emailData}
          selectedAgent={selectedAgentId}
        />
      )}

      {showDislikeFeedback && (
        <DislikeFeedback
          onClose={() => setShowDislikeFeedback(false)}
          chatDetailsId={currentFeedbackId}
          onSubmit={() => {
            setShowDislikeFeedback(false);
            toast.success("Thank you for your feedback!", {
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
              theme: "dark",
            });
          }}
        />
      )}
    </section>
  );
}

export default NewChat;
